import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Square,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { BsSearch } from 'react-icons/bs'
import * as React from 'react'

export var SearchField = (props) => {

  //DGFisher Theme (Default)
  var color = mode("whiteAlpha.800", "whiteAlpha.800")
  var bg = mode("whiteAlpha.200", "whiteAlpha.200")
  var hoverBg = mode("whiteAlpha.300", "whiteAlpha.300")
  
  if (props.themeName === 'PPMPortal') {
    color = mode("whiteAlpha.800", "whiteAlpha.800")
    bg = mode("whiteAlpha.200", "whiteAlpha.200")
  }

  return (
    <InputGroup bg={bg} color={color} _hover={{bg:(hoverBg)}} size="sm" {...props}>
      <InputLeftElement color={color}  pointerEvents="none">
        <BsSearch />
      </InputLeftElement>
      <Input
        rounded="md"
        placeholder="Search"
    
         _placeholder={{
           opacity: .75,
           color: (color),
           letterSpacing:(1),
           textTransform:'uppercase'
         }}

      />
    </InputGroup>
  )
}
