import { Box,Container,useColorModeValue,Flex,Heading,Spacer, HStack, Text, Stack,Button } from '@chakra-ui/react'
import React, { useState } from 'react';
import { log, formatDate } from './helperfunctions'
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';


export const PageContent = (props) => (


  <Flex key={'page1'} as="main" py="8" flex={1} w='full' bg={useColorModeValue('blackAlpha.50','')}  {...props} overflow='auto' className='skinnyScroll'>
    {/* bg="red.600" */}


    <Container maxW="8xl" >

      <Flex bg={useColorModeValue('white','gray.500')} p='6' rounded="lg" shadow="lg" flex={1} h='full' direction='column' minH='8xl' >

        <Flex color={useColorModeValue('gray.200', 'whiteAlpha.600')} h='full' rounded="lg" w='full' flex={1} direction='column' >


          <Stack w='full' px='4' flex={1} maxH='8xl' pb={4}>
            <Flex w='full' py='2'>
              <Heading letterSpacing={3} size='xl' textTransform='uppercase' color='gray.400'>MSGraph API Call</Heading>
            </Flex>
            <Flex  flex={1} w='full' h='full' overflow='auto' className='skinnyScroll' maxH='8xl'>
              <Flex rounded="lg" border="2px dashed currentColor" p='6' direction='column'  w='full' h='full' maxH='8xl'> 
                <ProfileContent/>
              </Flex>
            <Flex direction='column' h='full' px='1' maxH='8xl'>
            </Flex>

            </Flex>

          </Stack>


          <Stack w='full' px='4' flex={1} maxH='8xl' pb={4}>
            <Flex w='full' py='2'>
              <Heading letterSpacing={3} size='xl' textTransform='uppercase' color='gray.400'>SQL Database API Call</Heading>
            </Flex>
            <Flex  flex={1} w='full' h='full' overflow='auto' className='skinnyScroll' maxH='8xl'>
              <Flex rounded="lg" border="2px dashed currentColor" p='6' direction='column' flex={1} w='full' h='full' maxH='8xl'> 
                {props.data?.map((row, i) =>
                  
                  <HStack spacing={2} color='gray.500'>
                    <Text>{formatDate(row.Date)}</Text>
                    <Text>{row.Year}</Text>
                    <Text>{row.Month}</Text>
                    <Text>{row.MonthName}</Text>
                    <Text>{row.Day}</Text>
                    <Text>{row.WeekdayName}</Text>
                  </HStack>

                  )}
              </Flex>
            <Flex direction='column' h='full' px='1' maxH='8xl'>
            </Flex>

            </Flex>

          </Stack>


        </Flex>

      </Flex>

    </Container>
  </Flex>
)


const ProfileContent = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      instance
          .acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
          })
          .then((response) => {
              callMsGraph(response.accessToken).then((response) => setGraphData(response));
          });
  }

  return (
      <Stack>
          <Text color='gray.500' as='i'>Welcome {accounts[0].name}</Text>
          {graphData ? (
              <ProfileData graphData={graphData} />
      ) : (
          <Flex>
            <Button color='gray.600' onClick={RequestProfileData}>Get Profile </Button>
          </Flex>
          )}
      </Stack>
  );
};

const ProfileData = (props) => {
  log('GRAPHDATA', props.graphData)
  return (
    <Stack>
      <HStack>
        <Text fontWeight='bold' color="gray.500">MicrosoftID:</Text>
        <Text color='gray.500'>{props.graphData.id}</Text>
      </HStack>
      <HStack>
        <Text fontWeight='bold' color="gray.500">First Name:</Text>
        <Text color='gray.500'>{props.graphData.givenName}</Text>
      </HStack>
      <HStack>
        <Text fontWeight='bold' color="gray.500">Last Name:</Text>
        <Text color='gray.500'>{props.graphData.surname}</Text>
      </HStack>
      <HStack>
        <Text fontWeight='bold' color="gray.500">Email:</Text>
        <Text color='gray.500'>{props.graphData.userPrincipalName}</Text>
      </HStack>

      </Stack>
  );
};