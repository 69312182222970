import {
  Avatar,
  Box,
  Flex,
  HStack,
  Stack,
  Menu,
  MenuItem,
  MenuList,
  Text,
  Heading,
  useMenuButton,
  Divider,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'

import { NavItem } from './NavItem'

import AvatarDGFisher from './images/AvatarDGFisher.jpg';
import { HiMail} from 'react-icons/hi'
import { FaCog, FaBug, FaSignOutAlt, FaQuestionCircle } from 'react-icons/fa'
import { GoSignOut } from 'react-icons/go'

const UserAvatar = (props) => (
  <Avatar
    size={props.size}
    shadow='lg'
    src={AvatarDGFisher}
    name="Dustin Fisher"
  />
)

const ProfileMenuButton = (props) => {
  const buttonProps = useMenuButton(props)
  return (
    <Flex
      {...buttonProps}
      as="button"

      rounded="full"
      justify='flex-end'
      _focus={{
        shadow: 'outline',
      }}
    >
      <Box srOnly>Open user menu</Box>
      <UserAvatar size='md' />
    </Flex>
  )
}

export const ProfileDropdown = (props) => (
  <Menu>
    <Flex pl='3'>
    <ProfileMenuButton  />
    </Flex>
    <MenuList rounded="md" shadow="lg" py='3' px='3' color={mode('gray.500', 'inherit')} fontSize="lg" >
      <HStack p="2" spacing='4'>
        <UserAvatar size='lg' />
        <Stack spacing='1'>
          <Heading textTransform='uppercase' letterSpacing={1} size='md'>Dustin Fisher</Heading>
          <Text mt="1" fontSize="xs" textDecoration='underline' letterSpacing={1} color="gray.500">dustin@dgfisher.com</Text>
        </Stack>
      </HStack>
      <Divider my='2' />
      
      <Stack px='1' spacing='2' >
        <MenuItem icon={<FaCog size='1.75em' />} textTransform='uppercase' letterSpacing={1} fontSize='md' fontWeight="medium">
          Portal Settings
        </MenuItem>

        <MenuItem icon={<FaBug size='1.75em' />} textTransform='uppercase' letterSpacing={1} fontSize='md' fontWeight="medium">
          Report a Bug
        </MenuItem>

        <MenuItem icon={<FaQuestionCircle size='1.75em' />} textTransform='uppercase' letterSpacing={1} fontSize='md' fontWeight="medium">
          Suggestion Box
        </MenuItem>
        
        <MenuItem icon={<FaSignOutAlt size='1.75em' />} textTransform='uppercase' letterSpacing={1} fontSize='md' fontWeight="medium" onClick={() => props.handleLogout("redirect")}>
          Sign out
        </MenuItem>

      </Stack>
    </MenuList>
  </Menu>
);

