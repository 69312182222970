import { Avatar, Stack, HStack, Text, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

export const UserProfile = (props) => {
  const { name, image, email } = props
  return (
    <HStack spacing="4" p="3" flexShrink={0} borderTopWidth="1px" >
      <Avatar size="md" name={name} src={image} />
      <Stack spacing='1' fontWeight="medium">
        <Text fontSize="sm" letterSpacing={1} textTransform='uppercase' color={mode('whiteAlpha.800', 'whiteAlpha.800')}>{name}</Text>
        <Text fontSize="xs" letterSpacing={1} lineHeight="shorter" color={mode('whiteAlpha.600', 'whiteAlpha.600')}>
          {email}
        </Text>
      </Stack>
    </HStack>
  )
}
