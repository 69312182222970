import { Box,Divider,Flex,Spacer,Stack,useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import {
  FaRegBell,
  FaRegChartBar,
  FaRegClipboard,
  FaRegHeart,
  FaRegImages,
  FaRegQuestionCircle,
} from 'react-icons/fa'
import { Logo } from './Logo'
import { NavLink } from './NavLink'
import { SearchField } from './SearchField'
import { UserProfile } from './UserProfile'

import { NavMenu } from './NavMenu'
import { Notification } from './Notification'
import { ProfileDropdown } from './ProfileDropdown'
import { useMobileMenuState } from './useMobileMenuState'
import { ColorModeSwitcher } from './ColorModeSwitcher';

import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";


import AvatarDGFisher from './images/AvatarDGFisher.jpg';

export const Sidebar = (props) => {

  const { isOpen,onClose,onOpen } = useMobileMenuState()

  //DGFisher Theme (Default)
  var bg = mode("teal.700","teal.700");
  var color = mode("whiteAlpha.800","whiteAlpha.800");

  if (props.themeName === 'PPMPortal') {
    if (props.isOpen) {
      bg = mode("teal.700","teal.700")
      color = mode("whiteAlpha.800","whiteAlpha.800")
    } else {
      bg = mode("teal.700","teal.700")
      color = mode("whiteAlpha.800","whiteAlpha.800")
    }
  }

  return (
    <Flex
      bgGradient="linear(to-r, teal.900, teal.700)"
      direction="column"
      borderRightWidth="1px"
      width="64"
      shadow='lg'
      {...props}
    >
      <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4">
        {props.showLogo &&
          <Box mb="6">
            <Logo />
          </Box>
        }

        <Box mb="4">
          <SearchField themeName={props.themeName} />
        </Box>

        <Stack spacing="3" as="nav" aria-label="Sidebar Navigation">
          <NavMenu.Sidebar menuRoutes={props.menuRoutes[0]} basePath={props.basePath} />
          <Divider />
          <NavMenu.Sidebar menuRoutes={props.menuRoutes[1]} basePath={props.basePath} />
          <Divider />
          <NavMenu.Sidebar menuRoutes={props.menuRoutes[2]} basePath={props.basePath} />

          {/* <Stack spacing="2">
            <NavLink themeName={props.themeName} label="Images" icon={FaRegImages} isActive />
            <NavLink themeName={props.themeName} label="Favorites" icon={FaRegHeart} />
            <NavLink themeName={props.themeName} label="Notes" icon={FaRegClipboard} />
            <NavLink themeName={props.themeName} label="Statistics" icon={FaRegChartBar} />
          </Stack> */}

        </Stack>
        <Spacer />
      </Flex>

      <UserProfile
        name="Dustin Fisher"
        image={AvatarDGFisher}
        email="dustin@dgfisher.com"
      />
    </Flex>
  )
}
