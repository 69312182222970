import { Flex,Box } from '@chakra-ui/react'
import * as React from 'react'
import { Calendar,momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { PageHeader } from './PageHeader'
import { Sidebar } from './Sidebar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
const localizer = momentLocalizer(moment)

export class MyCalendar extends React.Component {
    state = {
        events: [
            {
                start: moment().toDate(),
                end: moment()
                    .add(1,"days")
                    .toDate(),
                title: "Some title"
            }
        ]
    };

    render() {
        return (

            <Flex flex="1" w='full' direction='column' bgGradient="linear(to-b, blackAlpha.100 75%, blackAlpha.200 )"  >
                <PageHeader pt='4' px='4' pageName={this.props.pageName} maxW='full' />
                <Flex direction='column' w='full' flex={1} p={4} bg='blackAlpha.300' >
                    <Flex direction='column' w='full' flex={1} rounded='5' bg='white' shadow='lg'>
                        <Calendar
                            localizer={localizer}
                            defaultDate={new Date()}
                            defaultView="month"
                            events={this.state.events}
                            style={{ height: "100%",minHeight: '500px',maxWidth: '100%',width: '100%' }}
                        />
                    </Flex>
                </Flex>
            </Flex>




        );
    }
}