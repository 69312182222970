import { Box,HStack,IconButton,Flex,Text } from '@chakra-ui/react'
import * as React from 'react'
import { Link } from "react-router-dom";


const MenuNavItem = (props) => {
  const { icon,label,active } = props

  return (
    <Link to={'/' + label.replace(/\s/g,'')}>
      <HStack
        as="a"
        aria-current={active ? 'page' : undefined}
        spacing="4"
        py="2"
        rounded="md"
        align='center'

        transition="all 0.2s"
        color="gray.500"
        _hover={{
          bg: 'whiteAlpha.200',
          textDecoration: 'underline'
        }}
        _activeLink={{
          bg: 'blackAlpha.300',
          color: 'white',
        }}
      >
        {icon && (
          <IconButton size='lg'>
            {icon}
          </IconButton>
        )}
        <Flex w='full' fontWeight="semibold">{label}</Flex>
      </HStack>
    </Link>
  )
}



const MobileNavItem = (props) => {
  const { label,href = '#',active } = props
  return (
    <Box
      as="a"
      display="block"
      href={href}
      px="3"
      py="3"
      rounded="md"
      fontWeight="semibold"
      aria-current={active ? 'page' : undefined}
      _hover={{
        bg: 'whiteAlpha.200',
      }}
      _activeLink={{
        bg: 'blackAlpha.300',
        color: 'white',
      }}
    >
      {label}
    </Box>
  )
}

const DesktopNavItem = (props) => {
  const { icon,label,active } = props
  return (
    <Link to={'/' + label.replace(/\s/g,'')}>
      <HStack
        as="a"
        aria-current={active ? 'page' : undefined}
        spacing="2"
        px="3"
        py="2"
        rounded="md"
        opacity='.75'
        transition="all 0.2s"
        color="gray.200"
        _hover={{
          bg: 'whiteAlpha.200',
          opacity: '1',
          textDecoration: 'underline'
        }}
        _activeLink={{
          bg: 'whiteAlpha.200',
          opacity: '1',
        }}
      >
        {icon && (
          <Box aria-hidden fontSize="md">
            {icon}
          </Box>
        )}
        <Box><Text fontWeight="semibold" textTransform='uppercase'>{label}</Text></Box>
      </HStack>
    </Link>
  )
}

const SidebarNavItem = (props) => {
  const { icon,label,active } = props
  return (
    <Link to={'/' + label.replace(/\s/g,'')}>
      <HStack
        as="a"
        aria-current={active ? 'page' : undefined}
        spacing="2"
        px="3"
        py="2"
        rounded="md"
        opacity='.75'
        transition="all 0.2s"
        color="gray.200"
        _hover={{
          bg: 'whiteAlpha.200',
          opacity: '1',
          textDecoration: 'underline'
        }}
        _activeLink={{
          bg: 'teal.600',
          opacity: '1',
        }}
      >
        {icon && (
          <Box aria-hidden fontSize="md">
            {icon}
          </Box>
        )}
        <Box><Text fontWeight="semibold" textTransform='uppercase'>{label}</Text></Box>
      </HStack>
    </Link>
  )
}

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
  Sidebar: SidebarNavItem,
  Menu: MenuNavItem,
}
