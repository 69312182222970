// import { v4 } from 'uuid';
// import { ajax } from 'jquery';
const portalURL = (process.env.NODE_ENV === 'development' ? 'http://localhost:9000' : window.location.origin) + "/dgfisherAPI";



export class DGFisherAPI {


    async environmentCheck() {
        if (process.env.NODE_ENV === 'development') {
            //let MSClient = new MS_Client();
            //let msUser = await MSClient.MS_LoggedInUser();
            //return msUser['id'];
            return true;
        } else {
            return false;
        }
    }

    async ajaxGet(url, body) {
        let isDev = await this.environmentCheck();

        let idHeader = 'USER';
        if (isDev) {
            //idHeader = isDev;
            idHeader = 'DEV'
        }

        let response = await fetch(url, {
            method: "GET",
            headers: {
                'environment': process.env.NODE_ENV,
                'Authentication': idHeader
            }
            
        })
        .then(res =>
            res.json().then(data => ({
                value: data,
                status: res.status
            })).then(response => {
                if (response.value?.error) {
                    alert("The Portal is currently updating, please refresh the page");
                }
                return response;
            })
        )
        .catch(async (err) => {
            if (err.name === 'AbortError') {
                console.log('Promise Aborted');
            }
            // else {
            //     console.log(err);
            //     console.log('Promise Rejected');
            //     let MSClient = new MS_Client();
            //     let msUser = await MSClient.MS_LoggedInUser();
            //     if (msUser['id']) {
            //         this.setUser(msUser['id']);
            //     }
            //     alert("An error occurred! Please refresh the page and try again.");
            //     return ({ status: 400, err: err });
            // }
            alert("An error occurred! Please refresh the page and try again.");
        })

        return response;
    }

    async ajaxPost(url, body, extraHeaders={}) {
        let isDev = await this.environmentCheck();

        let idHeader = 'USER';
        if (isDev) {
            idHeader = 'DEV';
            //idHeader = isDev;
        }

        let baseHeaders = {
            "Content-Type": "application/json",
            'environment': process.env.NODE_ENV,
            'Authentication': idHeader
        };

        let headers = {};
        Object.assign(headers, baseHeaders, extraHeaders);

        let response = await fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body)
        })
        .then(res =>
            res.json().then(data => ({
                value: data,
                status: res.status
            })).then(response => {
                if (response.value?.error) {
                    alert("The Portal is currently updating, please refresh the page");
                }
                return response;
            })
        )
        .catch(err => {
            if (err.name === 'AbortError') {
                console.log('Promise Aborted');
            } else {
                console.log(err);
                console.log('Promise Rejected');
                alert("An error occurred! Please refresh the page and try again.");
                return ({ status: 400, err: err });
            }
            alert("An error occurred! Please refresh the page and try again.");
        })

        return response;
    }

    async ajaxPostFile(url, body, headers= {}) {

        let response = await fetch(url, {
            method: "POST",
            headers: { 'Content-Type': 'multipart/form-data'},
            body: body,
        })
        .then(res =>
            res.json().then(data => {
                return data;
            })
        )
        .catch(err => {
            if (err.name === 'AbortError') {
                console.log('Promise Aborted');
            } else {
                console.log(err);
                console.log('Promise Rejected');
                return ({ status: 400, err: err });
            }
        })

        return response;
    }

    async ajaxPatch(url, body) {
        let isDev = await this.environmentCheck();

        let idHeader = 'USER';
        if (isDev) {
            idHeader = 'DEV';
            //idHeader = isDev;
        }

        let response = await fetch(url, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                'environment': process.env.NODE_ENV,
                'Authentication': idHeader
            },
            body: JSON.stringify(body)
        })
        .then(res =>
            res.json().then(data => ({
                value: data,
                status: res.status
            })).then(response => {
                if (response.value?.error) {
                    alert("The Portal is currently updating. Copy/paste or screenshot any recently entered information as it may not be saved. Please refresh the page once you are ready.");
                }
                return response;
            })
        )
        .catch(err => {
            if (err.name === 'AbortError') {
                console.log('Promise Aborted');
            } else {
                console.log(err);
                console.log('Promise Rejected');
                alert("An error occurred! Please refresh the page and try again.");
                return ({ status: 400, err: err });
            }
            alert("An error occurred! Please refresh the page and try again.");
        })

        return response;
    }

    async ajaxDelete(url) {
        let isDev = await this.environmentCheck();

        let idHeader = 'USER';
        if (isDev) {
            //idHeader = isDev;
            idHeader = 'DEV'
        }

        let response = await fetch(url, {
            method: "DELETE",
            headers: {
                'environment': process.env.NODE_ENV,
                'Authentication': idHeader
            }
        })
        .then(res =>
            res.json().then(data => ({
                value: data,
                status: res.status
            })).then(response => {
                if (response.value?.error) {
                    alert("The Portal is currently updating, please refresh the page");
                }
                return response;
            })
        )
        .catch(err => {
            if (err.name === 'AbortError') {
                console.log('Promise Aborted');
            } else {
                console.log(err);
                console.log('Promise Rejected');
                alert("An error occurred! Please refresh the page and try again.");
                return ({ status: 400, err: err });
            }
            alert("An error occurred! Please refresh the page and try again.");
        })

        return response;
    }


    GetDates() {
        var apiURL = portalURL + "/dates";
        return this.ajaxGet(apiURL);
    }



    // SetMicrosoftID(MicrosoftID, email) {
    //     var portalUrl = ppmportalUrl + "microsoftUser/" + MicrosoftID;
    //     let body = { email: email };
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // getMSgraphApiKeys() {
    //     var portalUrl = ppmportalUrl + 'msgraphApiKeys';
    //     return this.ajaxGet(portalUrl);
    // }

    // setUser(microsoftID) {
    //     var portalUrl = ppmportalUrl + 'setUser/'+ microsoftID;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetMicrosoftID(MicrosoftID) {
    //     var portalUrl = ppmportalUrl + "getMicrosoftID";
    //     return this.ajaxGet(portalUrl);
    // }

    // CreateNewUser(user) {
    //     var portalUrl = ppmportalUrl + "createNewUser";
    //     var data = new FormData();
    //     data.append("user", JSON.stringify(user));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetUser(MicrosoftID) {
    //     var portalUrl = ppmportalUrl + "user/"+ MicrosoftID;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetPortalConfig(MicrosoftID) {
    //     var portalUrl = ppmportalUrl + "portalConfig/"+ MicrosoftID;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetPortalData(employeeUID,tableName) {
    //     var portalUrl = ppmportalUrl + "portalConfig/"+ employeeUID + "/data?tableName="+ tableName;
    //     return this.ajaxGet(portalUrl);
    // }


    // GetAllUsers(queryParam) {
    //     var portalUrl = ppmportalUrl + "users?param="+ queryParam;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetAllPortalUsers() {
    //     var portalUrl = ppmportalUrl + "portalUsers";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetUsersLongevity() {
    //     var portalUrl = ppmportalUrl + 'usersLongevity';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetUsers(userArr) {
    //     var portalUrl = ppmportalUrl + "usersByGroup";
    //     let data = {group: userArr};
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdateEmployeeGroupManagement(userArr, updates) {
    //     var portalUrl = ppmportalUrl + "updateUserManagementMultiple";
    //     let data = {
    //         employees: userArr,
    //         updates: updates
    //     };
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetUserManagingTeam(employeeUid) {
    //     let portalUrl = ppmportalUrl + `user/${employeeUid}/managingTeam`;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetUserByEmployeeUID(employeeUID) {
    //     var portalUrl = ppmportalUrl + "userByEmployeeUID/"+ employeeUID;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetUserById(wpId) {
    //     var portalUrl = ppmportalUrl + "getUserById?wpId=" + wpId;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetUserByMicrosoftId(msId) {
    //     var portalUrl = ppmportalUrl + "getUserByMicrosoftId?msId=" + msId;
    //     return this.ajaxGet(portalUrl);
    // }

    // UpdateUser(msId, changes) {
    //     var portalUrl = ppmportalUrl + "updatePPMUser";
    //     var data = new FormData();
    //     data.append("msId", msId);
    //     data.append("changes", JSON.stringify(changes));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetUserInfo(MicrosoftID) {
    //     var portalUrl = ppmportalUrl + "getUserInfo?MicrosoftID="+ MicrosoftID;
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertMicrosoftId(MicrosoftID, Email) {
    //     var portalUrl = ppmportalUrl + "insertMicrosoftId?MicrosoftID="+ MicrosoftID +"&Email="+ Email;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetEmployeeOfficeInfo(Co, employeeId) {
    //     var portalUrl = ppmportalUrl + "employeelist?co=" + Co + "&employee=" + employeeId;
    //     return this.ajaxGet(portalUrl);
    // }

    // // function GetEmployeePayrollInfo(Co, employeeId) {
    // //   var portalUrl = ppmportalUrl + "payrollinfo?co=" + Co + "&employee=" + employeeId;
    // //   return this.ajaxGet(portalUrl);
    // // }

    // GetEmployeePayrollInfo(employeeId) {
    //     var portalUrl = ppmportalUrl + "getEmergencyContacts?employee=" + employeeId;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetUserPayStubs(MicrosoftID, year) {
    //     var portalUrl = ppmportalUrl + "getEmployeePayStubs?MicrosoftID="+ MicrosoftID +"&year=" + year;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetFieldWorkers() {
    //     var portalUrl = ppmportalUrl + "getFieldWorkers";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjects() {
    //     var portalUrl = ppmportalUrl + "projects";
    //     return this.ajaxGet(portalUrl);
    // }


    // GetUserProjectList(employeeUid) {
    //     var portalUrl = ppmportalUrl + "projectsMin/"+ employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetEmployeeHistory(employeeUid) {
    //     var portalUrl = ppmportalUrl + "employeeHistory/"+ employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }
    // GetEmployeeHistoryDetail(employeeUid) {
    //     var portalUrl = ppmportalUrl + "employeeHistoryDetail/"+ employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }
    
    // UpdateEmployeeHistory(keyId, update) {
    //     var portalUrl = ppmportalUrl + "employeeHistory/"+ keyId ;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // GetDepartmentLeaders(employeeUid) {
    //     var portalUrl = ppmportalUrl + "departmentLeaders/"+ employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetParentDepartments(employeeUid) {
    //     var portalUrl = ppmportalUrl + "parentDepartments/"+ employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // //*******************************MFR API CALLS***************************************//

    // GetMFRProjectList(employeeUid) {
    //     var portalUrl = ppmportalUrl + "projectsListMFR/"+ employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetUserMFRList(employeeUid) {
    //     var portalUrl = ppmportalUrl + "projectsMFR/"+ employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectManagerList(co) {
    //     var portalUrl = ppmportalUrl + "projectManagers/" + co;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectInfoById(keyId) {
    //     var portalUrl = ppmportalUrl + "project/" + keyId;
    //     return this.ajaxGet(portalUrl);
    // }

    // // OLD Contract Update
    // UpdateContractInfo(keyId, update) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdateJCCM(keyId, update) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId + "/jccm";
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdateJCJM(keyId, update) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId + "/jcjm";
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // GetContractStatusByProjMth(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/contractStatusByProjMth";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectAnalysisByProjMth(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/projectAnalysisByProjMth";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetCRSProjectAnalysisByProjMth(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/crsProjectAnalysisByProjMth";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetScopePercentagesByProjMth(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/scopePercentagesByProjMth";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectAccounting(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/accounting";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetCRSProjectAnalysis(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/crsProjectAnalysis";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetManpowerCostBillingScopes(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/ManpowerCostBillingScopes";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectionsByProjMth(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/projectionsByProjMth";
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertMFRProjections(update) {
    //     var portalUrl = ppmportalUrl + "mfr/projections";
    //     let data = update;
    //     return this.ajaxPost(portalUrl, data);
    // }
    
    // UpdateMFRProjections(keyId, update) {
    //     var portalUrl = ppmportalUrl + "mfr/projections/"+ keyId ;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // GetJobCostByProjMth(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/jobCostByProjMth";
    //     return this.ajaxGet(portalUrl);
    // }

    
    // GetContractItems(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/contractItems";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetChangeOrderLog(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/changeOrderLog";
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertPMOP(keyId, data) {
    //     var portalUrl = ppmportalUrl + "/project/"+ keyId + "/changeOrderLog/pmop";
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdatePMOP(pcoKeyID, data) {
    //     var portalUrl = ppmportalUrl + "project/changeOrderLog/pmop/update/"+ pcoKeyID;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // DeletePMOP(pcoKeyID) {
    //     var portalUrl = ppmportalUrl + "project/changeOrderLog/pmop/delete/"+ pcoKeyID ;
    //     return this.ajaxDelete(portalUrl);
    // }

    // InsertPMOI(pcoKeyID, data) {
    //     var portalUrl = ppmportalUrl + "project/changeOrderLog/pmoi/add/" + pcoKeyID;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdatePMOI(keyID, data) {
    //     var portalUrl = ppmportalUrl + "project/changeOrderLog/pmoi/update/"+ keyID ;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // DeletePMOI(keyID) {
    //     var portalUrl = ppmportalUrl + "project/changeOrderLog/pmoi/delete/"+ keyID ;
    //     return this.ajaxDelete(portalUrl);
    // }



    // GetProjectStatus(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/status";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectAnalysis(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/analysis";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectAnalysisCRS(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/analysisCrs";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetBillingAndCostProjections(keyId) {
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/billingAndCostProjections";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetManpowerCostBillingProjections(keyId, ProjMth) {
    //     ProjMth = ProjMth.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/ManpowerCostBillingProjections?ProjMth="+ProjMth;
    //     return this.ajaxGet(portalUrl);
    // }
    // GetManpowerCostBillingActuals(keyId, ProjMth) {
    //     ProjMth = ProjMth.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "project/"+ keyId +"/ManpowerCostBillingActuals?ProjMth="+ProjMth;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetHistoricalProjectsAddresses() {
    //     var portalUrl = ppmportalUrl + "getHistoricalProjectsAddresses";
    //     return this.ajaxGet(portalUrl);
    // }

    // UpdateHistoricalProjectsAddresses(project, location) {
    //     var portalUrl = ppmportalUrl + "updateHistoricalProjectsAddresses";
    //     var data = new FormData();
    //     data.append("project", project);
    //     data.append("location", JSON.stringify(location));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetProjectEmployees(Co, Contract) {
    //     var portalUrl = ppmportalUrl + "getProjectEmployees?co=" + Co + "&contract=" + Contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectInfoByContract(Co, Contract) {
    //     var portalUrl = ppmportalUrl + "getProjectInfo?co=" + Co + "&contract=" + Contract;
    //     return this.ajaxGet(portalUrl);
    // }





    // GetAllProjects() {
    //     var portalUrl = ppmportalUrl + "projects"
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectAccounting(Co, Contract) {
    //     var portalUrl = ppmportalUrl + "getProjAccounting?co=" + Co + "&contract=" + Contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectAnalysis(Co, Contract) {
    //     var portalUrl = ppmportalUrl + "getProjAnalysis?co=" + Co + "&contract=" + Contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectAnalysisCRS(Co, Contract) {
    //     var portalUrl = ppmportalUrl + "getProjAnalysisCRS?co=" + Co + "&contract=" + Contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetContractActiveLaborCodes(Co, Contract) {
    //     var portalUrl = ppmportalUrl + "getContractActiveLaborCodes?co=" + Co + "&contract=" + Contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetWeeklyRecapById(KeyID) {
    //     var portalUrl = ppmportalUrl + "getWeeklyRecapById?KeyID=" + KeyID;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetContractStatusById(KeyID) {
    //     var portalUrl = ppmportalUrl + "getContractStatusById?KeyID=" + KeyID;
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertTime() {
    //     var portalUrl = ppmportalUrl + "insertTime";
    //     return this.ajaxGet(portalUrl);
    // }

    // CreateTimeDistribution(payPeriod, coEmpl) {
    //     var portalUrl = ppmportalUrl + "createTimeDistribution";
    //     var data = new FormData();
    //     data.append("payPeriod", payPeriod);
    //     data.append("coEmpl", coEmpl);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // CreateTimeDistributionDetail() {
    //     var portalUrl = ppmportalUrl + "createTimeDistributionDetail";
    //     var data = new FormData();
    //     //data.append("payPeriod", payPeriod);
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetTimeDistributionDetails(payPeriod, coEmpl) {
    //     var portalUrl = ppmportalUrl + "getTimeDistributionDetails?payPeriod=" + payPeriod + "&coEmpl=" + coEmpl;
    //     return this.ajaxGet(portalUrl);
    // }

    // IUTimeDistributionDetail(employees) {
    //     var portalUrl = ppmportalUrl + "timeDistributionDetailIU";
    //     var data = new FormData();
    //     data.append("employees", JSON.stringify(employees));
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // IUTimeDistributionDetailField(employees) {
    //     var portalUrl = ppmportalUrl + "timeDistributionDetailIUField";
    //     var data = new FormData();
    //     data.append("employees", JSON.stringify(employees));
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertDailyReportJobInfo(dailyReportJobJson) {
    //     var portalUrl = ppmportalUrl + "insertDailyReportJobInfo";
    //     var data = new FormData();
    //     data.append("dailyReportJobJson", JSON.stringify(dailyReportJobJson));
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // SignDailyReport(dailyReportJobJson) {
    //     var portalUrl = ppmportalUrl + "signDailyReport";
    //     var data = new FormData();
    //     data.append("dailyReportJobJson", JSON.stringify(dailyReportJobJson));
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertDailyReportSurvey(surveyJson) {
    //     var portalUrl = ppmportalUrl + "insertDailyReportSurvey";
    //     var data = new FormData();
    //     data.append("surveyJson", JSON.stringify(surveyJson));
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertDailyReportPreTask(preTaskJSON) {
    //     var portalUrl = ppmportalUrl + "insertDailyReportPreTask";
    //     var data = new FormData();
    //     data.append("preTaskJSON", JSON.stringify(preTaskJSON));
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertDailyReportEquipmentOnSite(equipmentJSON) {
    //     var portalUrl = ppmportalUrl + "insertDailyReportEquipmentOnSite";
    //     var data = new FormData();
    //     data.append("equipmentJSON", JSON.stringify(equipmentJSON));
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // SubmitDailyReport(dailyReportJSON) {
    //     var portalUrl = ppmportalUrl + "submitDailyReport";
    //     var data = new FormData();
    //     data.append("dailyReportJSON", JSON.stringify(dailyReportJSON));
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetDailyReportInfo(Co, Contract, reportDate) {
    //     var portalUrl = ppmportalUrl + "getDailyReportInfo?Co=" + Co + "&Contract="+ Contract +"&reportDate="+ reportDate;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDailyReportSurvey(reportID) {
    //     var portalUrl = ppmportalUrl + "getDailyReportSurvey?reportID=" + reportID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDailyReportPreTaskPlan(reportID) {
    //     var portalUrl = ppmportalUrl + "getDailyReportPreTaskPlan?reportID=" + reportID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDailyReportEquipmentOnSite(reportID) {
    //     var portalUrl = ppmportalUrl + "getDailyReportEquipmentOnSite?reportID=" + reportID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDailyReportEquipmentChecklist(equipmentID) {
    //     var portalUrl = ppmportalUrl + "getDailyReportEquipmentChecklist?equipmentID=" + equipmentID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDailyReportCostCodesWorkedOn(reportID) {
    //     var portalUrl = ppmportalUrl + "getDailyReportCostCodesWorkedOn?reportID=" + reportID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDailyReportSubcontractors(reportID) {
    //     var portalUrl = ppmportalUrl + "getDailyReportSubcontractors?reportID=" + reportID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDailyReportWorkers(reportID) {
    //     var portalUrl = ppmportalUrl + "getDailyReportWorkers?reportID=" + reportID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDailyReportPictureAttachments(reportID) {
    //     var portalUrl = ppmportalUrl + "getDailyReportPictureAttachments?reportID=" + reportID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertDailyReportCostCode(dailyReportID, costCode) {
    //     var portalUrl = ppmportalUrl + "insertDailyReportCostCode";
    //     var data = new FormData();
    //     data.append("dailyReportID", dailyReportID);
    //     data.append("costCode", JSON.stringify(costCode));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteDailyReportCostCode(dailyReportID, costCode) {
    //     var portalUrl = ppmportalUrl + "deleteDailyReportCostCode";
    //     var data = new FormData();
    //     data.append("dailyReportID", dailyReportID);
    //     data.append("costCode", JSON.stringify(costCode));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertDailyReportSubcontractor(dailyReportID, subcontractor) {
    //     var portalUrl = ppmportalUrl + "insertDailyReportSubcontractor";
    //     var data = new FormData();
    //     data.append("dailyReportID", dailyReportID);
    //     data.append("subcontractor", JSON.stringify(subcontractor));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteDailyReportSubcontractor(dailyReportID, subcontractor) {
    //     var portalUrl = ppmportalUrl + "deleteDailyReportSubcontractor";
    //     var data = new FormData();
    //     data.append("dailyReportID", dailyReportID);
    //     data.append("subcontractor", JSON.stringify(subcontractor));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertDailyReportWorker(dailyReportID, employee) {
    //     var portalUrl = ppmportalUrl + "insertDailyReportWorker";
    //     var data = new FormData();
    //     data.append("dailyReportID", dailyReportID);
    //     data.append("employee", JSON.stringify(employee));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteDailyReportWorker(dailyReportID, employee) {
    //     var portalUrl = ppmportalUrl + "deleteDailyReportWorker";
    //     var data = new FormData();
    //     data.append("dailyReportID", dailyReportID);
    //     data.append("employee", JSON.stringify(employee));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertDailyReportWorkerWork(dailyReportID, employeeID, work) {
    //     var portalUrl = ppmportalUrl + "insertDailyReportWorkerWork";
    //     var data = new FormData();
    //     data.append("dailyReportID", dailyReportID);
    //     data.append("employeeID", employeeID);
    //     data.append("work", JSON.stringify(work));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteDailyReportWorkerWork(dailyReportID, employee) {
    //     var portalUrl = ppmportalUrl + "deleteDailyReportWorkerWork";
    //     var data = new FormData();
    //     data.append("dailyReportID", dailyReportID);
    //     data.append("employee", JSON.stringify(employee));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // AttachPictureData(dailyReportID, pictureAttachment) {
    //     var portalUrl = ppmportalUrl + "attachPictureData";
    //     var data = new FormData();
    //     data.append("dailyReportID", dailyReportID);
    //     data.append("pictureAttachment", JSON.stringify(pictureAttachment));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteDailyReportPicture(dailyReportID, pictureAttachment) {
    //     var portalUrl = ppmportalUrl + "deleteDailyReportPicture";
    //     var data = new FormData();
    //     data.append("dailyReportID", dailyReportID);
    //     data.append("pictureAttachment", JSON.stringify(pictureAttachment));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // SyncMicrosoftIDs(users) {
    //     var portalUrl = ppmportalUrl + "syncMicrosoftIDs";
    //     var data = new FormData();
    //     data.append("users", JSON.stringify(users));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetProjectCash() {
    //     var portalUrl = ppmportalUrl + "getProjectCash";
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }


    // GetApplicants() {
    //     var portalUrl = ppmportalUrl + "getApplicants";
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertApplicant(applicant) {
    //     var portalUrl = ppmportalUrl + "insertApplicant";
    //     var data = new FormData();
    //     data.append("applicant", JSON.stringify(applicant));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdateApplicant(applicantId, changes) {
    //     var portalUrl = ppmportalUrl + "updateApplicant";
    //     var data = new FormData();
    //     data.append("applicantId", applicantId);
    //     data.append("changes", JSON.stringify(changes));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetDeviceAssets() {
    //     var portalUrl = ppmportalUrl + "getDeviceAssets";
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDeviceAssetsAvailable() {
    //     var portalUrl = ppmportalUrl + "getDeviceAssetsAvailable";
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertDeviceAsset(asset) {
    //     var portalUrl = ppmportalUrl + "insertDeviceAsset";
    //     var data = new FormData();
    //     data.append("asset", JSON.stringify(asset));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdateDeviceAsset(assetId, changes) {
    //     var portalUrl = ppmportalUrl + "updateDeviceAsset";
    //     var data = new FormData();
    //     data.append("assetId", assetId);
    //     data.append("changes", JSON.stringify(changes));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetAllDepartments() {
    //     var portalUrl = ppmportalUrl + "getAllDepartments";
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDevTeamProjectDepartments() {
    //     var portalUrl = ppmportalUrl + "getDevTeamProjectDepartments";
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // DevProjectAddDepartment(planId, DepartmentTag) {
    //     var portalUrl = ppmportalUrl + "devProjectAddDepartment";
    //     var data = new FormData();
    //     data.append("planId", planId);
    //     data.append("DepartmentTag", DepartmentTag);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DevProjectDeleteDepartment(planId, DepartmentTag) {
    //     var portalUrl = ppmportalUrl + "devProjectDeleteDepartment";
    //     var data = new FormData();
    //     data.append("planId", planId);
    //     data.append("DepartmentTag", DepartmentTag);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertDevTeamProject(project) {
    //     var portalUrl = ppmportalUrl + "insertDevTeamProject";
    //     var data = new FormData();
    //     data.append("project", JSON.stringify(project));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteDevTeamProject(projectId) {
    //     var portalUrl = ppmportalUrl + "deleteDevTeamProject";
    //     var data = new FormData();
    //     data.append("projectId", projectId);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetDevTeamProjects() {
    //     var portalUrl = ppmportalUrl + "getDevTeamProjects";
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDevTeamProject(PlanId) {
    //     var portalUrl = ppmportalUrl + "getDevTeamProject?PlanId=" + PlanId;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // UpdateDevProject(planId, changes) {
    //     var portalUrl = ppmportalUrl + "updateDevProject";
    //     var data = new FormData();
    //     data.append("planId", planId);
    //     data.append("changes", JSON.stringify(changes));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetDevTeamProjectFeedback(PlanId) {
    //     var portalUrl = ppmportalUrl + "getDevTeamProjectFeedback?PlanId=" + PlanId;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertDevTeamProjectFeedback(feedbackEntry) {
    //     var portalUrl = ppmportalUrl + "insertDevTeamProjectFeedback";
    //     var data = new FormData();
    //     data.append("feedbackEntry", JSON.stringify(feedbackEntry));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdateDevProjectFeedback(id, notes) {
    //     var portalUrl = ppmportalUrl + "updateDevProjectFeedBack";
    //     var data = new FormData();
    //     data.append("id", id);
    //     data.append("notes", notes);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteDevProjectFeedback(id) {
    //     var portalUrl = ppmportalUrl + "deleteDevTeamProjectFeedback";
    //     var data = new FormData();
    //     data.append("id", id);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetDevTeamSprints() {
    //     var portalUrl = ppmportalUrl + "getDevTeamSprints";
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetSprintHourBudgets(sprintStart) {
    //     sprintStart = sprintStart.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "getSprintHourBudgets/"+sprintStart;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // UpdateSprintHourBudget(sprintStart, msId, changes) {
    //     var portalUrl = ppmportalUrl + "updateSprintHourBudget";
    //     var data = new FormData();
    //     data.append("sprintStart", sprintStart);
    //     data.append("msId", msId);
    //     data.append("changes", JSON.stringify(changes));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdateDevTeamSprintInfo(sprintStart, changes) {
    //     var portalUrl = ppmportalUrl + "updateDevTeamSprintInfo";
    //     var data = new FormData();
    //     data.append("sprintStart", sprintStart);
    //     data.append("changes", JSON.stringify(changes));
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetTeamReviews(managerUID) {
    //     var portalUrl = ppmportalUrl + "getTeamReviews?managerUID="+managerUID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetEmployeeReview(managerUID, employeeUID) {
    //     var portalUrl = ppmportalUrl + "getEmployeeReview?managerUID="+managerUID+"&employeeUID="+employeeUID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetPerformanceReviewForm(managerUID, employeeUID, formUID, reviewerType) {
    //     var portalUrl = ppmportalUrl + "getPerformanceReviewForm?managerUID="+managerUID+"&employeeUID="+employeeUID+"&formUID="+formUID+"&reviewerType="+reviewerType;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // UpdatePerformanceReviewResponse(responseId, value) {
    //     var portalUrl = ppmportalUrl + "updatePerformanceReviewResponse";
    //     var data = new FormData();
    //     data.append("responseId", responseId);
    //     data.append("value", value);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdatePerformanceReviewComments(commentId, value) {
    //     var portalUrl = ppmportalUrl + "updatePerformanceReviewComments";
    //     var data = new FormData();
    //     data.append("commentId", commentId);
    //     data.append("value", value);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdatePerformanceReviewHideComment(commentId, value) {
    //     var portalUrl = ppmportalUrl + "updatePerformanceReviewHideComment";
    //     var data = new FormData();
    //     data.append("commentId", commentId);
    //     data.append("value", value);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeletePerformanceReviewComment(commentId) {
    //     var portalUrl = ppmportalUrl + "deletePerformanceReviewComment";
    //     var data = new FormData();
    //     data.append("commentId", commentId);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetPerformanceReviewAvailableCommenters(employeeUID, formUID) {
    //     var portalUrl = ppmportalUrl + "getPerformanceReviewAvailableCommenters?employeeUID="+employeeUID+"&formUID="+formUID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetPerformanceReviewCommenters(employeeUID, formUID) {
    //     var portalUrl = ppmportalUrl + "getPerformanceReviewCommenters?employeeUID="+employeeUID+"&formUID="+formUID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // AddPerformanceReviewCommenter(employeeUID, formUID, commenterUID) {
    //     var portalUrl = ppmportalUrl + "addPerformanceReviewCommenter";
    //     var data = new FormData();
    //     data.append("employeeUID", employeeUID);
    //     data.append("formUID", formUID);
    //     data.append("commenterUID", commenterUID);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeletePerformanceReviewCommenter(employeeUID, formUID, commenterUID) {
    //     var portalUrl = ppmportalUrl + "deletePerformanceReviewCommenter";
    //     var data = new FormData();
    //     data.append("employeeUID", employeeUID);
    //     data.append("formUID", formUID);
    //     data.append("commenterUID", commenterUID);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // SubmitPerformanceReview(employeeUID, formUID, reviewerUID) {
    //     var portalUrl = ppmportalUrl + "submitPerformanceReview";
    //     var data = new FormData();
    //     data.append("employeeUID", employeeUID);
    //     data.append("formUID", formUID);
    //     data.append("reviewerUID", reviewerUID);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // SendAutomatedEmail(subject, body) {
    //     var portalUrl = "https://ppmportal.com/employeeportal/sendAutomatedEmail";
    //     var data = new FormData();
    //     data.append("subject", subject);
    //     data.append("body", body);
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetUserTimeoffRequests(employeeUID) {
    //     var portalUrl = ppmportalUrl + "user/"+ employeeUID +"/timeoffRequests";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetUserTimeoffRequestList(employeeUID) {
    //     var portalUrl = ppmportalUrl + "user/"+ employeeUID +"/timeoffRequestList";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetManagerTimeoffRequests(managerUID) {
    //     var portalUrl = ppmportalUrl + "management/"+ managerUID +"/timeOffRequests";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTimeOffRequestList(requestId) {
    //     let portalUrl = ppmportalUrl + 'timeOffRequest/all';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTimeOffRequest(requestId) {
    //     let portalUrl = ppmportalUrl + 'timeOffRequest/'+ requestId;
    //     return this.ajaxGet(portalUrl);
    // }

    // UpdateTimeOffRequest(requestId, update) {
    //     let portalUrl = ppmportalUrl + 'timeOffRequest/'+ requestId;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // GetEmployeeTimeoffRequests(managerUID) {
    //     var portalUrl = ppmportalUrl + "getEmployeeTimeoffRequests?managerUID="+managerUID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertTimeoffRequest(request) {
    //     var portalUrl = ppmportalUrl + "timeoffRequest";
    //     let data = {};
    //     data['request'] = request;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteTimeOffRequest(requestId) {
    //     let portalUrl = ppmportalUrl + 'timeOffRequest/'+ requestId +'/delete';
    //     return this.ajaxDelete(portalUrl);
    // }

    // CreateSprint(SprintStart, SprintEnd) {
    //     SprintStart = SprintStart.replace(/\//gm, '-');
    //     SprintEnd = SprintEnd.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "createSprint/"+SprintStart + "/" + SprintEnd;
    //     var data = new FormData();
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetOrgChartEmployees() {
    //     var portalUrl = ppmportalUrl + "getOrgChartEmployees";
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTimecardEarnCodes(PRCo) {
    //     var portalUrl = ppmportalUrl + "earnCodes/"+ PRCo;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTimecardPayWeekHours(EmployeeUID, co, PREndDate) {
    //     PREndDate = PREndDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "payweekHours/"+ EmployeeUID + "/co/" + co + "?PREndDate="+ PREndDate;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTimecardInfo(EmployeeUID, co, PREndDate) {
    //     PREndDate = PREndDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "timecardInfo/"+ EmployeeUID + "/co/" + co + "?PREndDate="+ PREndDate;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTimecardJobList(EmployeeUID) {
    //     var portalUrl = ppmportalUrl + "timecardJoblist/"+ EmployeeUID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetAllTimecardPayWeekHours(PREndDate) {
    //     PREndDate = PREndDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "payweekHoursAll/"+ PREndDate;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertTimecardRow(timecardRow) {
    //     let employeeUID = timecardRow['employeeUID'];
    //     var portalUrl = ppmportalUrl + "payweekHours/" + employeeUID;
    //     timecardRow['prEndDate'] = timecardRow['prEndDate'].replace(/\//gm, '-');
    //     let data = timecardRow;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdateTimecardRow(KeyID, updates) {
    //     var portalUrl = ppmportalUrl + "payweekHours/" + KeyID;
    //     var data = updates;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdateTimecardInfo(KeyID, updates) {
    //     var portalUrl = ppmportalUrl + "timecardInfo/" + KeyID;
    //     var data = updates;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdateTimecardInfoMultiple(keyIds, updates) {
    //     var portalUrl = ppmportalUrl + "timecardInfoMultiple";
    //     var data = {
    //         keyIds: keyIds,
    //         update: updates
    //     };
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // DeleteTimecardRow(KeyID) {
    //     var portalUrl = ppmportalUrl + "payweekHours/" + KeyID;
    //     return this.ajaxDelete(portalUrl);
    // }

    // CloneTimecard(employeeUid, prEndDate, co) {
    //     var portalUrl = ppmportalUrl + "timecard/" + employeeUid +"/clone";
    //     //prEndDate = prEndDate.replace(/\//gm, '-');
    //     let data = {
    //         prEndDate: prEndDate,
    //         co: co
    //     };
    //     return this.ajaxPost(portalUrl, data);
    // }

    // // Newest Timecard Functions (4/21/2021)

    // GetResourceGroups(EmployeeUID, groupType) {
    //     var portalUrl = ppmportalUrl + "resourceGroups/"+ EmployeeUID + "?groupType="+ groupType;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetPayrollResourceGroups(EmployeeUID) {
    //     var portalUrl = ppmportalUrl + "payrollResourceGroups/"+ EmployeeUID;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetResourceGroupMembers(EmployeeUID, groupUID, groupType, prEndDate) {
    //     prEndDate = prEndDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "resourceGroupMembers/"+ EmployeeUID + "/group/" + groupUID + "?groupType="+ groupType + "&prEndDate="+ prEndDate;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertResourceGroupMember(groupUid, employeeUid) {
    //     var portalUrl = ppmportalUrl + "resourceGroupMember/" + groupUid + '/member/' + employeeUid;
    //     return this.ajaxPost(portalUrl);
    // }

    // DeleteResourceGroupMember(groupUid, employeeUid) {
    //     var portalUrl = ppmportalUrl + "resourceGroupMember/" + groupUid + '/member/' + employeeUid;
    //     return this.ajaxDelete(portalUrl);
    // }

    // UpdateResourceGroupMember(groupUid, employeeUid, update) {
    //     var portalUrl = ppmportalUrl + "resourceGroupMember/" + groupUid + '/member/' + employeeUid;
    //     var data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // GetResourceGroupTimecard(groupUID, prEndDate) {
    //     prEndDate = prEndDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "resourceGroupTimecard/" + groupUID + "?prEndDate="+ prEndDate;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTimecardExceptions(prEndDate) {
    //     prEndDate = prEndDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "timecardExceptions/" +  prEndDate;
    //     return this.ajaxGet(portalUrl);
    // }


    // GetResourceGroupTimecardHeaders(groupUID, prEndDate) {
    //     prEndDate = prEndDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "resourceGroupTimecardHeaders/" + groupUID + "?prEndDate="+ prEndDate;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetResourceGroupTimecardEntry(groupUID, prEndDate) {
    //     prEndDate = prEndDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "resourceGroupTimecardEntry/" + groupUID + "?prEndDate="+ prEndDate;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetResourceGroupTimecardAll(groupUid, parameters) {
    //     let portalUrl = ppmportalUrl + 'resourceGroupTimecardAll/' + groupUid;
    //     let data = parameters;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetTimecardPayPeriods(EmployeeUID, status) {
    //     var portalUrl = ppmportalUrl + "timecardPayPeriods/"+ EmployeeUID + "?status="+ status;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTimecardJobSecurity(EmployeeUID, co) {
    //     var portalUrl = ppmportalUrl + "timecardJobSecurity/"+ EmployeeUID + "/co/"+ co;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTimecardReviewJobs(EmployeeUID, prEndDate) {
    //     prEndDate = prEndDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "timecardReviewJobs/"+ EmployeeUID + "?prEndDate="+ prEndDate;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }
    
    // GetTimecardReviewJobPhases(KeyID, prEndDate) {
    //     prEndDate = prEndDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + "timecardReviewJobPhases/"+ KeyID + "?prEndDate="+ prEndDate;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTimecardJobPhases(job, co) {
    //     var portalUrl = ppmportalUrl + "timecardJobPhases/"+ job + "/co/"+ co;
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // async InsertTimecardBatch(selectedEmployeeIds, batchInfo, employeeUID) {
    //     let portalUrl = ppmportalUrl + "timecardBatchesSelectedEmployee";
    //     let data = {};
    //     data['selectedEmployees'] = selectedEmployeeIds;
    //     data['batchInfo'] = batchInfo;
    //     data['employeeUID'] = employeeUID;
    //     await this.ajaxPost(portalUrl, data);

    //     portalUrl = ppmportalUrl + "timecardBatchesAdd";
    //     return this.ajaxPost(portalUrl, data);
    // }

    // async GetBids() {
    //     var portalUrl = ppmportalUrl + "bids";

    //     return this.ajaxGet(portalUrl);
    // }


    // // Form Functions

    // InsertFormTemplate(templateForm) {
    //     var portalUrl = ppmportalUrl + "formTemplate";
    //     // templateForm['dateCreated'] = templateForm['dateCreated'].replace(/\//gm, '-');
    //     // templateForm['dateModified'] = templateForm['dateModified'].replace(/\//gm, '-');
    //     // templateForm['dateCompleted'] = templateForm['dateCompleted'].replace(/\//gm, '-');
    //     let data = templateForm;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertFormSection(formId, formSection) {
    //     var portalUrl = ppmportalUrl + "formTemplate/" + formId + '/section';
    //     let data = formSection;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertFormQuestion(formId, question) {
    //     var portalUrl = ppmportalUrl + "formTemplate/" + formId + '/question';
    //     let data = question;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertFormQuestionDetail(questionId, questionDetail) {
    //     var portalUrl = ppmportalUrl + "formTemplate/" + questionId + '/questionDetail';
    //     let data = questionDetail;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertUpdateQuestion(formId, questionId, subValues) {
    //     let portalUrl = ppmportalUrl + 'formTemplate/' + formId + '/question/' + questionId;
    //     let data = subValues;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // InsertUpdateFormComment(commentId, comment) {
    //     let portalUrl = ppmportalUrl + 'formComment/' + commentId;
    //     let data = comment;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetFormTemplateList() {
    //     var portalUrl = ppmportalUrl + "listFormTemplate";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetPerformanceReviewList(assigned, getBy=false) {
    //     var portalUrl = ppmportalUrl + "listPerformanceReview/"+ assigned;
    //     if (getBy) {
    //         portalUrl += '?getBy='+ getBy;
    //     }
    //     return this.ajaxGet(portalUrl);
    // }

    // GetEmployeeFormList(assigned, getBy=false) {
    //     var portalUrl = ppmportalUrl + "listEmployeeForms/"+ assigned;
    //     // if (getBy) {
    //     //     portalUrl += '?getBy='+ getBy;
    //     // }
    //     return this.ajaxGet(portalUrl);
    // }

    // GetAllEmployeeForms(assigned, getBy=false) {
    //     var portalUrl = ppmportalUrl + "listAllEmployeeForms/"+ assigned;
    //     if (getBy) {
    //         portalUrl += '?getBy='+ getBy;
    //     }
    //     return this.ajaxGet(portalUrl);
    // }

    // AddFormColumn() {
    //     let portalUrl = ppmportalUrl + 'form/addColumn';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetEmployeeQuestionsForm(employeeUid, year) {
    //     let filter = {
    //         FormType: 1,
    //         'YEAR(DateCreated)': (year ?? '2022')
    //     };

    //     let portalUrl = ppmportalUrl + `forms/${employeeUid}?filter=${JSON.stringify(filter)}`;
    //     return this.ajaxGet(portalUrl);
    // }

    // DeleteForm(formId) {
    //     var portalUrl = ppmportalUrl + `/form/${formId}/delete`;
    //     return this.ajaxDelete(portalUrl);
    // }

    // GetHRPerformanceReviewList() {
    //     var portalUrl = ppmportalUrl + "listHrPerformanceReview";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetHRPerformanceReviewListAll() {
    //     var portalUrl = ppmportalUrl + "listHrPerformanceReviewAll";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetFormListByTemplateId(templateUid) {
    //     let portalUrl = ppmportalUrl + `formListByTemplate/${templateUid}`;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetGroupFormInputs(formId) {
    //     var portalUrl = ppmportalUrl + "groupFormInputs/"+ formId;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetGroupFormComments(formId) {
    //     var portalUrl = ppmportalUrl + "groupFormComments/"+ formId;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetInvitePerformanceReviewList(employeeUid) {
    //     var portalUrl = ppmportalUrl + "listInvitePerformanceReview/"+ employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetPerformanceReview(formId) {
    //     var portalUrl = ppmportalUrl + "performanceReview/"+ formId;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetFormTemplate(formId) {
    //     var portalUrl = ppmportalUrl + "formTemplate/"+ formId;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetFormTemplateAll(formId) {
    //     var portalUrl = ppmportalUrl + "formTemplate/"+ formId + '/all';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetFormSections(formId) {
    //     var portalUrl = ppmportalUrl + "formTemplate/"+ formId + '/section';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetFormQuestions(formId) {
    //     var portalUrl = ppmportalUrl + "formTemplate/"+ formId + '/question';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetFormQuestionDetails(formId) {
    //     var portalUrl = ppmportalUrl + "formTemplate/"+ formId + '/questionDetail';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetFormQuestionInputs(formId) {
    //     var portalUrl = ppmportalUrl + "formTemplate/"+ formId + '/questionInputs';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetFormQuestionComments(formId) {
    //     var portalUrl = ppmportalUrl + "form/"+ formId + '/questionComments';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetFormAll(formId, parameters) {
    //     let portalUrl = ppmportalUrl + 'form/' + formId + '/all';
    //     let data = parameters;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetCommentersByGroup(formArr) {
    //     var portalUrl = ppmportalUrl + "commentersByFormGroup";
    //     let data = {group: formArr};
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteFormQuestionInput(questionId, inputId) {
    //     let portalUrl = ppmportalUrl + 'formQuestion/'+ questionId + '/input/'+ inputId +'/delete';
    //     return this.ajaxDelete(portalUrl);
    // };

    // DeleteFormQuestion(questionId) {
    //     let portalUrl = ppmportalUrl + 'formQuestion/'+ questionId +'/delete';
    //     return this.ajaxDelete(portalUrl);
    // }

    // DeleteFormQuestionDetail(id) {
    //     let portalUrl = ppmportalUrl + `formQuestionDetail/${id}/delete`;
    //     return this.ajaxDelete(portalUrl);
    // }

    // DeleteFormSection(sectionId) {
    //     let portalUrl = ppmportalUrl + 'formSection/'+ sectionId +'/delete';
    //     return this.ajaxDelete(portalUrl);
    // }

    // DeleteFormTemplate(formId) {
    //     let portalUrl = ppmportalUrl + 'formTemplate/'+ formId + '/delete';
    //     return this.ajaxDelete(portalUrl);
    // }

    // UpdateForm(formId, update) {
    //     var portalUrl = ppmportalUrl + 'form/'+ formId;
    //     var data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // ResetForm(formId) {
    //     let portalUrl = ppmportalUrl + 'form/' + formId + '/reset';
    //     let data = {};
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdateFormTemplate(formId, update) {
    //     var portalUrl = ppmportalUrl + 'formTemplate/'+ formId;
    //     var data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdateFormSection(sectionId, update) {
    //     let portalUrl = ppmportalUrl + 'formSection/'+ sectionId;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdateFormQuestion(questionId, update) {
    //     let portalUrl = ppmportalUrl + 'formQuestion/'+ questionId;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdateFormQuestionDetail(id, update) {
    //     let portalUrl = ppmportalUrl + 'formQuestionDetail/'+ id;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdateFormQuestionInput(questionId, inputId, update) {
    //     let portalUrl = ppmportalUrl + 'formQuestion/'+ questionId + '/input/'+ inputId;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // InsertFormCommenter(formId, commenterUid) {
    //     let portalUrl = ppmportalUrl + 'form/'+ formId + '/commenter';
    //     let data = {commenterUid: commenterUid};
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteFormCommenter(formId, commenterUid) {
    //     let portalUrl = ppmportalUrl + 'form/'+ formId + '/commenter/'+ commenterUid + '/delete';
    //     return this.ajaxDelete(portalUrl);
    // }

    // DeleteFormComment(commenterUid) {
    //     let portalUrl = ppmportalUrl + 'formComment/'+ commenterUid + '/delete';
    //     return this.ajaxDelete(portalUrl);
    // }

    // GetFormCommenters(formId) {
    //     let portalUrl = ppmportalUrl + 'form/'+ formId + '/commenter';
    //     return this.ajaxGet(portalUrl);
    // }

    // UpdateFormCommenter(formId, keyId, updates) {
    //     let portalUrl = ppmportalUrl + 'form/'+ formId + '/commenter/'+ keyId;
    //     let body = updates;
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // AutoFillParentForm(formUid) {
    //     let portalUrl = ppmportalUrl + `form/${formUid}/autofillParent`;
    //     let body = {};
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // IsChildFormsCompleted(formUid) {
    //     let portalUrl = ppmportalUrl + `form/${formUid}/isResponsesCompleted`;
    //     return this.ajaxGet(portalUrl);
    // }

    // DuplicateForm(duplicateForm) {
    //     let portalUrl = ppmportalUrl + 'duplicateFormTemplate/'+ duplicateForm.duplicateFormId;
    //     let data = duplicateForm;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetForm(formId) {
    //     let portalUrl = ppmportalUrl + `form/${formId}`;
    //     return this.ajaxGet(portalUrl);
    // }

    // async InsertAssignForm(selectedAssignees, assignedType, formId) {
    //     let portalUrl = '';
    //     let data;
    //     let assignments = [];
    //     if (assignedType !== 'Employee') {
    //         let employeeLevel = '';
    //         if (assignedType === 'Manager') {
    //             employeeLevel = 'ManagerUID';
    //         } else if (assignedType === 'Reviewer') {
    //             employeeLevel = 'ReviewerUID';
    //         }

    //         portalUrl = ppmportalUrl + 'userManagement/group/' + employeeLevel;
    //         data = selectedAssignees;
    //         let managementResult = await this.ajaxPost(portalUrl, data);
    //         console.log('managementResult');
    //         console.log(managementResult);
    //         if (managementResult.status === 200) {
    //             for (let i = 0; i < managementResult.value.length; i++) {
    //                 if (assignedType === 'Manager') {
    //                     assignments.push({newId: v4(), assignee: managementResult.value[i].ManagerUID, subject: managementResult.value[i].EmployeeUID });
    //                 } else if (assignedType === 'Reviewer') {
    //                     assignments.push({newId: v4(), assignee: managementResult.value[i].ReviewerUID, subject: managementResult.value[i].EmployeeUID });
    //                 }
    //             }
    //         }
    //     } else {
    //         for (let i = 0; i < selectedAssignees.length; i++) {
    //             assignments.push({newId: v4(), assignee: selectedAssignees[i], subject: selectedAssignees[i] });
    //         }
    //     }
    //     portalUrl = ppmportalUrl + 'assignForm/' + formId;
    //     data = assignments;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // AssignForms(forms, formId) {
    //     let portalUrl = ppmportalUrl + `assignFormsMultiple/${formId}`;
    //     let data = {
    //         forms: forms
    //     };
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetEmployeeManager(employeeId) {
    //     let portalUrl = ppmportalUrl + 'userManagement/group/EmployeeUID';
    //     let data = [employeeId];
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetTeam(employeeUid) {
    //     let portalUrl = ppmportalUrl + 'userManagement/group/ManagerUID';
    //     let data = [employeeUid];
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetEmployeeGroupManagement(employeeIds, managementLevel) {
    //     managementLevel = managementLevel ?? 'EmployeeUID';
    //     let portalUrl = ppmportalUrl + `userManagement/group/${managementLevel}`;
    //     let data = employeeIds;
    //     return this.ajaxPost(portalUrl, data);
    // }
    
    // GetEmergencyContacts(employeeUid) {
    //     var portalUrl = ppmportalUrl + "user/" + employeeUid + '/emergencycontacts';
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertEmergencyContact(employeeUid, contactUid, updates) {
    //     var portalUrl = ppmportalUrl + "user/" + employeeUid + '/emergencycontacts/' + contactUid;
    //     let data = updates;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdateEmergencyContact(employeeUid, contactUid, updates) {
    //     var portalUrl = ppmportalUrl + "user/" + employeeUid + '/emergencycontacts/' + contactUid;
    //     let data = updates;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // DeleteEmergencyContact(employeeUid, contactUid) {
    //     var portalUrl = ppmportalUrl + "user/" + employeeUid + '/emergencycontacts/' + contactUid;
    //     return this.ajaxDelete(portalUrl);
    // }

    // GetFAQs() {
    //     var portalUrl = ppmportalUrl + "faqs/";
    //     return this.ajaxGet(portalUrl);
    // }

    // GetPowerBILinks() {
    //     var portalUrl = ppmportalUrl + "powerbi/";
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertPowerBILink(reportUID) {
    //     var portalUrl = ppmportalUrl + "powerbi/" + reportUID;
    //     return this.ajaxPost(portalUrl);
    // }

    // UpdatePowerBILink(reportUID, updates) {
    //     var portalUrl = ppmportalUrl + "powerbi/" + reportUID;
    //     let data = updates;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdatePREmployee(employeeUid, updates) {
    //     var portalUrl = ppmportalUrl + "premployee/" + employeeUid;
    //     let data = updates;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // UpdatePPMUser(employeeUid, updates) {
    //     var portalUrl = ppmportalUrl + "user/" + employeeUid;
    //     let data = updates;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // GetMyTeam(employeeUid) {
    //     var portalUrl = ppmportalUrl + "team/" + employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetCalendarEvents(options) {
    //     var portalUrl = ppmportalUrl + "calendarEvents?";
    //     if (options) {
    //         let optionKeys = Object.keys(options);
    //         for (let i = 0; i < optionKeys.length; i++) {
    //             if (i !== 0) {
    //                 portalUrl += '&';
    //             }
    //             portalUrl += optionKeys[i] + '=' + options[optionKeys[i]];
    //         }
    //     }
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTeamCalendar(employeeUid, eventType) {
    //     var portalUrl = ppmportalUrl + "teamCalendar/" + employeeUid +'?eventType='+eventType;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetMFRCalendar(employeeUid, eventType) {
    //     var portalUrl = ppmportalUrl + "mfrCalendar/" + employeeUid +'?eventType='+eventType;
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertCalendarEvent(event) {
    //     var portalUrl = ppmportalUrl + "calendarEvents";
    //     let data = event;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // DeleteCalendarEvent(eventId) {
    //     var portalUrl = ppmportalUrl + "calendarEvents/"+ eventId + '/delete';
    //     return this.ajaxDelete(portalUrl);
    // }

    // UpdateCalendarEvent(eventId, update) {
    //     var portalUrl = ppmportalUrl + "calendarEvents/"+ eventId;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // GetCompanyAnnouncements() {
    //     var portalUrl = ppmportalUrl + "companyAnnouncementList";
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertCompanyAnnouncement(announcement) {
    //     var portalUrl = ppmportalUrl + "companyAnnouncement/";
    //     let data = announcement;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdateCompanyAnnouncement(announcementUid, update) {
    //     var portalUrl = ppmportalUrl + "companyAnnouncement/"+ announcementUid;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // DeleteCompanyAnnouncement(announcementUid) {
    //     var portalUrl = ppmportalUrl + "companyAnnouncement/"+ announcementUid + '/delete';
    //     return this.ajaxDelete(portalUrl);
    // }

    // GetReviewers(employeeUid) {
    //     var portalUrl = ppmportalUrl + "reviewers/" + employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetApprovers(employeeUid) {
    //     var portalUrl = ppmportalUrl + "approvers/" + employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetReviewHierarchy(employeeUid) {
    //     var portalUrl = ppmportalUrl + "userReviewers/" + employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // UpdateEmployeeManagement(employeeUid, update) {
    //     let portalUrl = ppmportalUrl + 'userReviewers/'+ employeeUid;
    //     let data = update;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // /*****************************************************************/
    // /*************  Paystub Api Calls ***************************/
    // /*****************************************************************/

    // GetPaystubList(employeeUid) {
    //     let portalUrl = ppmportalUrl + 'paystubList/' + employeeUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetPaystub(attachmentId) {
    //     let portalUrl = ppmportalUrl + 'paystub/' + attachmentId;
    //     return this.ajaxGet(portalUrl);
    // }


    // /*****************************************************************/
    // /*************  Notification Api Calls ***************************/
    // /*****************************************************************/

    // GetAllNotifications() {
    //     let portalUrl = ppmportalUrl + 'notificationList/all';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetUserNotifications(employeeUid) {
    //     let portalUrl = ppmportalUrl + 'notificationList/' + employeeUid;
    //     return this.ajaxGet(portalUrl);
    // } 

    // GetNotification(notificationUid) {
    //     let portalUrl = ppmportalUrl + 'notification/' + notificationUid;
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertNotification(notification) {
    //     let portalUrl = ppmportalUrl + 'notification';
    //     let data = notification;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // UpdateNotification(notificationUid, updates) {
    //     let portalUrl = ppmportalUrl + 'notification/' + notificationUid;
    //     let data = updates;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // DismissAllNotifications(employeeUid, updates) {
    //     let portalUrl = ppmportalUrl + 'user/' + employeeUid + '/notification/dismissAll';
    //     let data = updates;
    //     return this.ajaxPatch(portalUrl, data);
    // }

    // DeleteNotification(notificationUid) {
    //     let portalUrl = ppmportalUrl + 'notification/' + notificationUid + '/delete';
    //     return this.ajaxDelete(portalUrl);
    // }

    // SendgridEmail(msg) {
    //     let portalUrl = ppmportalUrl + 'sendgrid';
    //     let data = msg;
    //     return this.ajaxPost(portalUrl, data);
    // }


    // /*****************************************************************/
    // /**********************  DnF Api Calls ***************************/
    // /*****************************************************************/

    // GetTemplateDeliverableList() {
    //     let portalUrl = ppmportalUrl + 'dnf/templateDeliverableList';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTemplateList() {
    //     let portalUrl = ppmportalUrl + 'dnf/templateList';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetScheduleList() {
    //     let portalUrl = ppmportalUrl + 'dnf/scheduleList';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTemplateDeliverable(id) {
    //     let portalUrl = ppmportalUrl + 'dnf/templateDeliverable/'+ id;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTemplateWorkflow(id) {
    //     let portalUrl = ppmportalUrl + 'dnf/templateWorkflow/'+ id;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetTemplate(id) {
    //     let portalUrl = ppmportalUrl + 'dnf/template/'+ id;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDnfScheduleFull(id) {
    //     let portalUrl = ppmportalUrl + `dnf/schedule/${id}/full`;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetSchedule(id) {
    //     let portalUrl = ppmportalUrl + 'dnf/schedule/'+ id;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDnfTasks(scheduleId) {
    //     let portalUrl = ppmportalUrl + `dnf/schedule/${scheduleId}/tasks` ;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDnfDeliverables(scheduleId) {
    //     let portalUrl = ppmportalUrl + `dnf/schedule/${scheduleId}/deliverables` ;
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertTemplateDeliverable(deliverable) {
    //     let portalUrl = ppmportalUrl + 'dnf/templateDeliverable';
    //     let body = deliverable;
    //     return this.ajaxPost(portalUrl, body);
    // }

    // InsertTemplate(templateSchedule) {
    //     let portalUrl = ppmportalUrl + 'dnf/template';
    //     let body = templateSchedule;
    //     return this.ajaxPost(portalUrl, body);
    // }

    // InsertSchedule(schedule) {
    //     let portalUrl = ppmportalUrl + 'dnf/schedule';
    //     let body = schedule;
    //     return this.ajaxPost(portalUrl, body);
    // }

    // InsertScheduleTask(task) {
    //     let portalUrl = ppmportalUrl + 'dnf/scheduleTask';
    //     let body = task;
    //     return this.ajaxPost(portalUrl, body);
    // }

    // InsertScheduleDeliverable(deliverable) {
    //     let portalUrl = ppmportalUrl + 'dnf/scheduleDeliverable';
    //     let body = deliverable;
    //     return this.ajaxPost(portalUrl, body);
    // }

    // UpdateTemplateDeliverable(id, updates) {
    //     let portalUrl = ppmportalUrl + 'dnf/templateDeliverable/'+ id;
    //     let body = updates;
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // UpdateTemplate(id, updates) {
    //     let portalUrl = ppmportalUrl + 'dnf/template/'+ id;
    //     let body = updates;
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // UpdateSchedule(id, updates) {
    //     let portalUrl = ppmportalUrl + 'dnf/schedule/'+ id;
    //     let body = updates;
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // UpdateScheduleTask(id, updates) {
    //     let portalUrl = ppmportalUrl + 'dnf/scheduleTask/'+ id;
    //     let body = updates;
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // UpdateScheduleDeliverable(id, updates) {
    //     let portalUrl = ppmportalUrl + 'dnf/scheduleDeliverable/'+ id;
    //     let body = updates;
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // UpdateScheduleDeliverableMultiple(updates) {
    //     let portalUrl = ppmportalUrl + 'dnf/deliverableMultiple';
    //     let body = updates;
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // DeleteScheduleTask(id) {
    //     let portalUrl = ppmportalUrl + 'dnf/scheduleTask/'+ id;
    //     let body = {};
    //     return this.ajaxDelete(portalUrl, body);
    // }

    // GetDnfTeam(scheduleId) {
    //     let portalUrl = ppmportalUrl + `dnf/team/${scheduleId}`;
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertDnfTeam(team) {
    //     let portalUrl = ppmportalUrl + `dnf/team`;
    //     let body = team;
    //     return this.ajaxPost(portalUrl, body);
    // }

    // UpdateTeamMember(memberId, updates) {
    //     let portalUrl = ppmportalUrl + `dnf/member/${memberId}`;
    //     let body = updates;
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // GetDnfScheduleFiles(scheduleId) {
    //     let portalUrl = ppmportalUrl + `dnf/schedule/${scheduleId}/files`;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDnfTaskFiles(taskId) {
    //     let portalUrl = ppmportalUrl + `dnf/task/${taskId}/files`;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetDnfDeliverableFiles(deliverableId) {
    //     let portalUrl = ppmportalUrl + `dnf/deliverable/${deliverableId}/files`;
    //     return this.ajaxGet(portalUrl);
    // }

    // UpdateEgnyteFile(fileId, updates) {
    //     let portalUrl = ppmportalUrl + `egnyteFile/${fileId}`;
    //     let body = updates;
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // InsertDnfEgnyteFile(deliverableId, files) {
        
    //     let portalUrl = ppmportalUrl + `dnf/deliverable/${deliverableId}/files`;
    //     let body = files;
    //     return this.ajaxPost(portalUrl, body);
    // }

    // UpdateDnfTaskFilePath(taskId, oldPath, newPath) {
    //     let portalUrl = ppmportalUrl + `dnf/task/${taskId}/files/pathRename`;
    //     let body = { oldPath: oldPath, newPath: newPath };
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // UpdateDnfDeliverableFilePath(templateDelivId, oldPath, newPath) {
    //     let portalUrl = ppmportalUrl + `dnf/scheduleDeliverable/${templateDelivId}/files/pathRename`;
    //     let body = {oldPath: oldPath, newPath: newPath};
    //     return this.ajaxPatch(portalUrl, body);
    // }

    // DeleteDnfTaskFiles(taskId) {
    //     let portalUrl = ppmportalUrl + `dnf/task/${taskId}/files/delete`;
    //     return this.ajaxDelete(portalUrl);
    // }

    // DeleteEgnyteFile(path, entryId) {
    //     let portalUrl = ppmportalUrl + `egnyteFile/${path}`;
    //     if (Boolean(entryId)) {
    //         portalUrl += `?entryId=${entryId}`;
    //     }
    //     return this.ajaxDelete(portalUrl);
    // }

    // InsertTeamMember(member, teamId) {
    //     let portalUrl = ppmportalUrl + `dnf/team/${teamId}/members`;
    //     return this.ajaxPost(portalUrl, member);
    // }

    // GetTeamMembers(teamId) {
    //     let portalUrl = ppmportalUrl + `dnf/team/${teamId}/members`;
    //     return this.ajaxGet(portalUrl);
    // }



    // /**************************** Operations ***************************/

    // GetProjectCashflow(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/cashflow?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectNetflow(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/netflow?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }


    // GetProjectPendingChangeOrders(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/pendingChangeOrders?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectApprovedChangeOrders(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/approvedChangeOrders?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectPCONums(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/pcoNums?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectChangeOrderConversion(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/changeOrderConversion?co='+co +'&contract='+ contract +'&begMth=6-1-2019';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectChangeOrderStatus(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/changeOrderStatus?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }
    

    // GetProjectLaborProjectionHeader(co, contract, endDate) {
    //     endDate = endDate.replace(/\//gm, '-');
    //     var portalUrl = ppmportalUrl + 'project/1/laborProjectionHeader?co='+co +'&contract='+ contract +'&endDate='+ endDate;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectLaborProjectionDetail(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/laborProjectionDetail?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectLevelsAndAreas(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/levelsAndAreas?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectLocalHireRequirements(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/localHireRequirements?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectLocalHireEmployees(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/localHireEmployees?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectLocalHireZipCodes(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/localHireZipCodes?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectLocalHireHoursToDate(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/localHireHoursToDate?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectMarginTrend(co, contract, thruMth) {
    //     var portalUrl = ppmportalUrl + 'project/1/marginTrend?co='+co +'&contract='+ contract +'&thruMth='+ thruMth;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectTimecardHours(co, contract, prEndDate) {
    //     var portalUrl = ppmportalUrl + 'project/1/timecardHours?co='+co +'&contract='+ contract;
    //     if (Boolean(prEndDate)) {
    //         prEndDate = prEndDate.replace(/\//gm, '-');
    //         portalUrl += '&prEndDate='+ prEndDate;
    //     }
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectLaborHours(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/laborHours?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectAllHours(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/allHours?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectAllHoursByWeek(co, contract) {
    //     // Could Use Renaming
    //     var portalUrl = ppmportalUrl + 'project/1/allHoursByWeek?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectFieldBurnsheet(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/fieldBurnsheet?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }

    // GetProjectLevelAreas(co, contract) {
    //     var portalUrl = ppmportalUrl + 'project/1/levelAreaCodes?co='+co +'&contract='+ contract;
    //     return this.ajaxGet(portalUrl);
    // }


    // // Storage Blob Calls
    // CreateTestContainer() {
    //     var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     url += 'testContainer';
    //     return this.ajaxPost(url);
    // }

    // CreateBlobContainer(containerName) {
    //     var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     url += 'container';
    //     let body = {
    //         containerName: containerName
    //     };
    //     return this.ajaxPost(url, body);
    // }

    // DeleteBlobContainer(containerName) {
    //     //var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     let url = 'http://localhost:9000/blobStorage/'
    //     url += `container/${containerName}`;
    //     return this.ajaxDelete(url);
    // }

    // CreateTestBlob(containerName) {
    //     var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     url += 'testBlob';
    //     let body = {
    //         containerName: containerName
    //     };
    //     return this.ajaxPost(url, body);
    // }

    // CreateBlob(containerName, content, blobName) {
    //     var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     url += `container/${containerName}/blob`;
    //     let body = {
    //         content: content,
    //         blobName: blobName
    //     };
    //     return this.ajaxPost(url, body);
    // }

    // CreateBlobWithData(containerName, content, blobName) {
    //     //var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     let url = 'http://localhost:9000/blobStorage/'
    //     url += `container/${containerName}/blobData`;
    //     let body = {
    //         content: content,
    //         blobName: blobName
    //     }
    //     let data = new FormData();
    //     //data.append('content', 'hi');
    //     data.append('blobName', content);
    //     let headers = {'Content-Disposition': 'form-data; name="file"; filename="' + blobName + '"' };
    //     return this.ajaxPostFile(url, data);
    // }

    // CreateBlobWithFile(containerName, content, blobName) {
    //     var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     url += `container/${containerName}/blobFile`;
    //     let body = {
    //         content: content,
    //         blobName: blobName
    //     };
    //     return this.ajaxPost(url, body);
    // }

    // ListContainers() {
    //     var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     url += 'listContainers';
    //     return this.ajaxGet(url);
    // }

    // ListBlobs(containerName) {
    //     var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     url += `container/${containerName}/listBlobs`;
    //     return this.ajaxGet(url);
    // }

    // GetBlob(containerName, blobName) {
    //     //var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     let url = 'http://localhost:9000/blobStorage/';
    //     url += `container/${containerName}/blob/${blobName}`;
    //     return this.ajaxGet(url);
    // }

    // DeleteBlob(containerName, blobName) {
    //     //var url = (process.env.NODE_ENV === 'development' ? 'https://ppmportalappnodejs-alpha.azurewebsites.net' : window.location.origin) + "/blobStorage/";
    //     let url = 'http://localhost:9000/blobStorage/';
    //     url += `container/${containerName}/blob/${blobName}`;
    //     return this.ajaxDelete(url);
    // }



    // /*****************************************************************/
    // /*************  Viewpoint To Viewport Sync Procedures ************/
    // /*****************************************************************/

    // ViewportProjectSetup() {
    //     var portalUrl = ppmportalUrl + "viewportProjectSetup";
    //     //data.append("coEmpl", coEmpl);
    //     return this.ajaxGet(portalUrl);
    // }

    // TestPost() {
    //     var portalUrl = ppmportalUrl + "testPost";
    //     // var data = new FormData();
    //     // data.append("test", "testValue");
    //     // data.append("value", "Another test value");
    //     // data.append("work", {id: 1, 'value': "hello"});
    //     var data = {
    //         test: 'testValue',
    //         "value": 'actual value',
    //         "work": {
    //             'id': 1,
    //             'value': "hello"
    //         }
    //     };
    //     return this.ajaxPost(portalUrl, data);
    // }

    // TestGet() {
    //     var portalUrl = ppmportalUrl + "testGet";

    //     return this.ajaxGet(portalUrl);
    // }

    // SSRSTest1() {
    //     var portalUrl = testUrl + "ssrsTest1";
    //     return this.ajaxGet(portalUrl);
    // }

    // SSRSTest2() {
    //     var portalUrl = testUrl + "ssrsTest2";
    //     return this.ajaxGet(portalUrl);
    // }



    // GetViewportTables() {
    //     var portalUrl = 'https://ppmportalappnodejs-alpha.azurewebsites.net/viewport/tableList';
    //     return this.ajaxGet(portalUrl);
    // }

    // InsertArticle(article) {
    //     var portalUrl = 'https://ppmportalappnodejs-alpha.azurewebsites.net/viewport/article';
    //     let data = article;
    //     return this.ajaxPost(portalUrl, data);
    // }

    // GetArticles() {
    //     var portalUrl = 'https://ppmportalappnodejs-alpha.azurewebsites.net/viewport/articles';
    //     return this.ajaxGet(portalUrl);
    // }

    // GetArticle(id) {
    //     var portalUrl = 'https://ppmportalappnodejs-alpha.azurewebsites.net/viewport/articles/'+ id;
    //     return this.ajaxGet(portalUrl);
    // }


}
