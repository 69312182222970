// import { PPM_Api } from '../APIs/ppmApi';
// import { log } from '../helperFunctions';

import { useIsAuthenticated } from '@azure/msal-react';


export class User {
    constructor(user) {

        this.MicrosoftID = user?.MicrosoftID ?? null;
        this.FirstName = user?.FirstName ?? '';
        this.LastName = user?.LastName ?? '';
        this.Email = user?.Email ?? '';
        //this.birthDay = (user?.BirthDate?.replace(/T.*/gm,' 00:00:00').replace(/-/gm, '/') ?? user?.Birthday?.replace(/T.*/gm,' 00:00:00').replace(/-/gm, '/') ?? user?.birthDay) ?? null;

    }
    
    initAuthenticatedUser() {
        // let ppmUserResult = await PPMApi.GetUser(msId);
        // if (ppmUserResult.status === 400) {
        //     return ppmUserResult.status;
        // }
        
        // if (ppmUserResult.status === 200) {
        //     ppmUserResult = ppmUserResult.value;
        //     let mainUser = null;
        //     if (ppmUserResult.length > 0) {
        //         mainUser = ppmUserResult[0];
        //     }
        //     let activeCompanies = [];
        //     for (let i = 0; i < ppmUserResult.length; i++) {
        //         ppmUserResult[i] = new User(ppmUserResult[i]);
        //         if (ppmUserResult[i].activeYN === 'Y') {
        //             activeCompanies.push(ppmUserResult[i]);
        //         }
                
        //     }
        //     let loggedInUser = new User(mainUser, activeCompanies);
        //     return loggedInUser;
        // } else if (ppmUserResult.status === 404) {
        //     return false;
        // } else {
        //     return false;
        // }
    }

 

    // async loadUserByMSId(msId) {
    //     let ppmUserResult = await PPMApi.GetUser(msId);
    //     if (ppmUserResult.status === 400) {
    //         return ppmUserResult.status;
    //     }
        
    //     if (ppmUserResult.status === 200) {
    //         ppmUserResult = ppmUserResult.value;
    //         let mainUser = null;
    //         if (ppmUserResult.length > 0) {
    //             mainUser = ppmUserResult[0];
    //         }
    //         let activeCompanies = [];
    //         for (let i = 0; i < ppmUserResult.length; i++) {
    //             ppmUserResult[i] = new User(ppmUserResult[i]);
    //             if (ppmUserResult[i].activeYN === 'Y') {
    //                 activeCompanies.push(ppmUserResult[i]);
    //             }
                
    //         }
    //         let loggedInUser = new User(mainUser, activeCompanies);
    //         return loggedInUser;
    //     } else if (ppmUserResult.status === 404) {
    //         return false;
    //     } else {
    //         return false;
    //     }
    // }

    // async loadPortalConfigByMSId(msId) {
    //     let portalConfigData = await PPMApi.GetPortalConfig(msId)
    //     let portalConfig = new PortalConfigTables();
    //     if (portalConfigData.status === 200) {
    //         portalConfigData = new PortalConfigData(portalConfigData.value);
    //         log('portalConfigData',portalConfigData)
    //         portalConfig = portalConfigData.createConfigMap('tableName')
    //         log('portalConfig',portalConfig)
            
    //     } 
    //     this.portalConfig = portalConfig;
    // }

    // isPortalUser() {
    //     if (this.microsoftId === null) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    // async updateDB(updates) {
    //     let result = await PPMApi.UpdatePPMUser(this.employeeUID, updates);
    //     if (result.status === 200) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    // async updatePREmployee(updates) {
    //     let result = await PPMApi.UpdatePREmployee(this.employeeUID, updates);
    //     if (result.status === 200) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    // async updateFirstName(value) {
    //     this.firstName = value.replace("'","`");
    //     this.fullName = (value + ' ' + this.lastName)
    //     let updates = {
    //         FirstName: this.firstName
    //     };
    //     return await this.updateDB(updates);
    // }

    // async updateLastName(value) {
    //     this.lastName = value.replace("'","`");
    //     this.fullName = (this.firstName + ' ' + value.replace("'","`"))
    //     let updates = {
    //         LastName: this.lastName
    //     };
    //     return await this.updateDB(updates);
    // }

    // async updateWorkCell(value, sync) {
    //     this.workCell = value.replace("'","`");
    //     let result;
    //     if (sync) {
    //         let updates = {
    //             CellPhone: this.workCell
    //         };
    //         result = await this.updateDB(updates)
    //     } else {
    //         result = true;
    //     }
    //     return result;
    // }

    // async updatePhoneCarrier(value) {
    //     this.phoneCarrier = value.replace("'","`");
    //     let updates = {
    //         PhoneCarrier: this.phoneCarrier
    //     };
    //     return await this.updateDB(updates);
    // }

    // async updateShirtSize(value) {
    //     this.shirtSize = value.replace("'","`");
    //     let updates = {
    //         ShirtSize: this.shirtSize
    //     };
    //     return await this.updateDB(updates);
    // }

    // async updateShowBirthday(value) {
    //     this.showBirthday = value;
    //     let updates = {
    //         ShowBirthday: this.showBirthday
    //     };
    //     return await this.updateDB(updates);
    // }

    // async updateSharePhotos(value) {
    //     this.sharePhotos = value;
    //     let updates = {
    //         SharePhotos: this.sharePhotos
    //     };
    //     return await this.updateDB(updates);
    // }

    // async updatePaystubEmail(value) {
    //     this.paystubEmail = value.replace("'","`").toLowerCase();
    //     let updates = {
    //         'p.Email': this.paystubEmail
    //     };
    //     return await this.updatePREmployee(updates);
    // }

    // async updateMailAddress(value) {
    //     this.mailAddress = value.replace("'","`");
    //     let updates = {
    //         'p.Address': this.mailAddress
    //     };
    //     return await this.updatePREmployee(updates);
    // }

    // async updateMailCity(value) {
    //     this.mailCity = value.replace("'","`");
    //     let updates = {
    //         'p.City': this.mailCity
    //     };
    //     return await this.updatePREmployee(updates);
    // }

    // async updateMailState(value) {
    //     this.mailState = value.replace("'","`").toUpperCase();
    //     let updates = {
    //         'p.State': this.mailState
    //     };
   
    //     return await this.updatePREmployee(updates);
    // }

    // async updateMailZip(value) {
    //     this.mailZip = value.replace("'","`");
    //     let updates = {
    //         'p.Zip': this.mailZip
    //     };
    //     return await this.updatePREmployee(updates);
    // }

    // async updatePortalTheme(value) {
    //     this.portalTheme = value.replace("'","`");
    //     let updates = {
    //         PortalTheme: value
    //     };
    //     return await this.updateDB(updates);
    // }

    // async updateShowPortalTour(value) {
    //     this.showPortalTour = value;
    //     let updates = {
    //         showPortalTour: this.showPortalTour
    //     };
    //     return await this.updateDB(updates);
    // }

    // async updateApprover(approverUid) {
    //     let updates = {
    //         ApproverUID: approverUid
    //     }
    //     //return await this.update
    // }
    
    // async updateTitle(title, sync=false) {
    //     this.title = title;
    //     let update = {
    //         Title: title.replace(/'/gm, "''")
    //     }
    //     if (!sync) {
    //         this.pushToUnsynced('ppmUser', update);
    //     }
    // }

    // async updateDepartment(deptId, sync=false) {
    //     this.deptId = deptId;
    //     let update = {
    //         DeptID: deptId
    //     }
    //     if (!sync) {
    //         this.pushToUnsynced('ppmUser', update);
    //     }
    // }

    // async updateManagement(managementLevel, employeeUid, sync=false) {
    //     this[managementLevel] = employeeUid;

    //     switch (managementLevel) {
    //         case 'managerUid': managementLevel = 'ManagerUID'; break;
    //         case 'reviewerUid': managementLevel = 'ReviewerUID'; break;
    //         case 'approverUid': managementLevel = 'ApproverUID'; break;
    //     }
    //     let update = {
    //         [managementLevel]: employeeUid
    //     };
    //     if (!sync) {
    //         this.pushToUnsynced('management', update);
    //     }
    // }

    // pushToUnsynced(table, update) {
    //     if (!Boolean(this.unsyncedChanges[table])) {
    //         this.unsyncedChanges[table] = {};
    //     }
    //     for (let key in update) {
    //         this.unsyncedChanges[table][key] = update[key];
    //     }
    //     //log('Unsynced Changes', this.unsyncedChanges);
    // }

    // async syncAll() {
    //     for (let table in this.unsyncedChanges) {
    //         if (table === 'management') {
    //             let result = await PPMApi.UpdateEmployeeManagement(this.employeeUID, this.unsyncedChanges[table]);
    //             //log('Sync Result', result);
    //         } else if (table === 'ppmUser') {
    //             let result = await PPMApi.UpdatePPMUser(this.employeeUID, this.unsyncedChanges[table]);
    //             //log('Sync Result', result);
    //         }
    //     }
    //     this.unsyncedChanges = {};
    //     //log('Changes Synced', this.unsyncedChanges);
    // }


    // getLongevityAward() {
    //     let longevityAwards = [5.0, 7.0, 10.0, 15.0, 20.0, 25.0, 30.0, 35.0, 40.0, 45.0, 50.0, 55.0, 60.0];
    //     let awardYear = null;
    //     let mthsWorked = 0;
    //     if (this.longevityMths) {
    //         mthsWorked = this.longevityMths;
    //     } 
    //     for (let i = 0; i < longevityAwards.length; i++) {
        
    //         if ((mthsWorked >= ((longevityAwards[i]*12)-1)) && (mthsWorked <= ((longevityAwards[i]*12)+0)) ) {
    //             awardYear = parseInt(longevityAwards[i]);
    //             break;
    //         }
    //     }
    //     return awardYear;
    // }

    // getFullName() {
    //     return this.firstName + ' ' + this.lastName;
    // }

    // getLongevityColor(awardYear) {

    //     let color = 'gray.400';
    
    //     if (awardYear === 5) {
    
    //         color = 'yellow.400';
    
    //     } else if (awardYear === 7){
    
    //         color = 'yellow.500';
    
    //     } else if (awardYear === 10){
    
    //         color = 'orange.300';
    
    //     } else if (awardYear === 15){
    
    //         color = 'orange.400';
    
    //     } else if (awardYear === 20){
    
    //         color = 'red.500';
    
    //     } else if (awardYear === 25){
    
    //         color = 'red.600';
    
    //     } else {
    
    //         color='red.600';
    
    //     }
    
    //     return color;
    
    
    
    // }

    // getDeptInfo() {
    //     if (this.deptId === 1 || this.dept === 'Executive') {
    //       return {tag: 'EXEC', icon: 'fas fa-user-tie fa-fw', colorScheme: 'teal', color:'teal.500'};
    //     } else if (this.deptId === 2 || this.dept === 'Accounting') {
    //       return {tag: 'ACCT', icon: 'fas fa-dollar-sign fa-fw', colorScheme: 'green', color:'green.500'};
    //     } else if (this.deptId === 3 || this.dept === 'Accounts Payable') {
    //         return {tag: 'AP', icon: 'fas fa-money-check-alt fa-fw', colorScheme: 'green', color:'green.300'};
    //     } else if (this.deptId === 4 || this.dept === 'Accounts Receivable') {
    //         return {tag: 'AR', icon: 'fas fa-hand-holding-usd fa-fw', colorScheme: 'green', color:'green.400'};
    //     } else if (this.deptId === 5 || this.dept === 'Payroll') {
    //         return {tag: 'PR', icon: 'fas fa-user-clock fa-fw', colorScheme: 'teal', color:'teal.400'};
    //     } else if (this.deptId === 6 || this.dept === 'Purchasing') {
    //         return {tag: 'PURCH', icon: 'fas fa-file-invoice-dollar fa-fw', colorScheme: 'yellow', color:'yellow.400'};
    //     } else if (this.deptId === 7 || this.dept === 'Operations') {
    //         return {tag: 'OPS', icon: 'fas fa-building fa-fw', colorScheme: 'blue', color:'blue.600'};
    //     } else if (this.deptId === 8 || this.dept === 'Service Management') {
    //         return {tag: 'SM', icon: 'fas fa-tools fa-fw', colorScheme: 'blue', color:'blue.500'};
    //     } else if (this.deptId === 9 || this.dept === 'Field') {
    //         return {tag: 'FIELD', icon: 'fas fa-hard-hat fa-fw', colorScheme: 'cyan', color:'cyan.500'};
    //     } else if (this.deptId === 10 || this.dept === 'Fabrication') {
    //         return {tag: 'SHOP', icon: 'fas fa-warehouse fa-fw', colorScheme: 'cyan', color:'cyan.400'};
    //     } else if (this.deptId === 11 || this.dept === 'Fleet Managment') {
    //         return {tag: 'FLEET', icon: 'fas fa-truck fa-fw', colorScheme: 'cyan', color:'cyan.300'};
    //     } else if (this.deptId === 12 || this.dept === 'Preconstruction & Sales') {
    //         return {tag: 'PRECON', icon: 'fas fa-funnel-dollar fa-fw', colorScheme: 'red', color:'red.400'};
    //     } else if (this.deptId === 13 || this.dept === 'Estimating') {
    //         return {tag: 'EST', icon: 'fas fa-calculator fa-fw', colorScheme: 'red', color:'red.300'};
    //     } else if (this.deptId === 14 || this.dept === 'Detailing') {
    //         return {tag: 'DET', icon: 'fas fa-pencil-ruler fa-fw', colorScheme: 'orange', color:'orange.500'};
    //     } else if (this.deptId === 15 || this.dept === 'Engineering') {
    //         return {tag: 'ENG', icon: 'fas fa-drafting-compass fa-fw', colorScheme: 'orange', color:'orange.400'};
    //     } else if (this.deptId === 16 || this.dept === 'QA/QC') {
    //         return {tag: 'QAQC', icon: 'far fa-check-square fa-fw', colorScheme: 'orange', color:'orange.300'};
    //     } else if (this.deptId === 17 || this.dept === 'Information Technology') {
    //         return {tag: 'IT', icon: 'fas fa-laptop fa-fw', colorScheme: 'gray', color:'gray.300'};
    //     } else if (this.deptId === 18 || this.dept === 'DevOps') {
    //         return {tag: 'DEV', icon: 'fas fa-code fa-fw', colorScheme: 'gray', color:'teal.500'};
    //     } else if (this.deptId === 19 || this.dept === 'Human Resources') {
    //         return {tag: 'HR', icon: 'far fa-handshake fa-fw', colorScheme: 'purple', color:'purple.500'};
    //     } else if (this.deptId === 20 || this.dept === 'Safety') {
    //         return {tag: 'SAFE', icon: 'fas fa-vest fa-fw', colorScheme: 'purple', color:'purple.400'};
    //     } else if (this.deptId === 21 || this.dept === 'Marketing') {
    //         return {tag: 'MRKT', icon: 'fas fa-bullhorn fa-fw', colorScheme: 'purple', color:'purple.300'};
    //     } else {
    //         return {tag: '', icon: 'fas fa-bullhorn fa-fw', colorScheme: 'red', color:'red.300'};
    //     }
    //   }
    
    // hasCrewTime() {
    //     if (this.timecardApprovalCount !== 0 || this.timecardCrewEntryCount !== 0) {
    //         return true;
    //     }
    // }

    // async getProfilePicture(callback) {
    //     let msClient = new MS_Client();
    //     let pics = await msClient.MS_GetUsersPhotos([this]);
    //     for (let i in pics) {
    //         this.profilePicture = pics[i];
    //     }
    //     if (callback) {
    //         callback(this);
    //     }
    // }
}


