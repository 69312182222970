import React, { useState } from 'react';
import { ChakraProvider, Box, Center, Flex, Text, theme, Button, VStack, HStack, Heading , Divider} from '@chakra-ui/react';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { useIsAuthenticated } from '@azure/msal-react';
import { User } from './sdk/User';

export let user = new User();
const basePath = window.location.href;  



import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { TopNav } from './TopNav';
import { MobileTopBar } from './MobileTopBar';
import { Sidebar } from './Sidebar';
import { Home, Components } from './Home';
import { MyCalendar } from './Calendar';



export default function App() {


    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/"
            });
            window.location.reload();
        }
    }

    
    return (
        <ChakraProvider theme={theme}>
            {/* <PageLayout> */}

            <UnauthenticatedTemplate>
                <Flex h="100vh" flexDirection="column" bg='blackAlpha.500'>
                    <Center h='75vh' w='100vw' >
                        <Center bg='whiteAlpha.800' minW='lg' minH='xs' p='8' rounded='lg' align='center' shadow='xl'>
                            <VStack spacing={4}>
                                <Divider/>
                                <Flex align='flex-end'>
                                    <Heading size='2xl' letterSpacing={6} textTransform='uppercase' color='gray.500' >DGFisher</Heading>
                                    <Heading  size='lg' letterSpacing={4} textTransform='uppercase' color='gray.400'>.com</Heading>
                                </Flex>
                                <Divider/>
                                <Heading size='md' letterSpacing={2} textTransform='uppercase' color='gray.400'>Please Sign In</Heading>
                                <Button colorScheme='teal' rightIcon={<i className="fa-solid fa-right-to-bracket"></i>} onClick={() => handleLogin("redirect")}>
                                    <Text textTransform='uppercase' letterSpacing={1} fontWeight='bold'>Sign In</Text>
                                </Button>
                            </VStack>
                        </Center>
                    </Center>
                </Flex>
                
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                {/* <Center h='100vh' w='100vw'>
                    <VStack bg='blackAlpha.100' minW='container.sm' p='8' rounded='lg'>
                        <Heading size='md' letterSpacing={2} textTransform='uppercase' color='gray.400'>Please Sign Out</Heading>
                        <Button colorScheme='teal' rightIcon={<i className="fa-solid fa-right-from-bracket"></i>} onClick={() => handleLogout("redirect")}>
                            <Text textTransform='uppercase' letterSpacing={1} fontWeight='bold'>Sign Out</Text>
                        </Button>
                    </VStack>
                </Center> */}
                <Router>
                    <Flex h="100vh" flexDirection="column">
                        <TopNav
                            themeName={'PPMPortal'}
                            menuRoutes={menuRoutes}
                            basePath={basePath}
                            handleLogout={handleLogout}
                        />
                        <MobileTopBar themeName={'PPMPortal'} menuRoutes={menuRoutes} basePath={basePath} />
                        <Flex flex="1" overflow="hidden">
                            <Sidebar themeName={'PPMPortal'} menuRoutes={menuRoutes} basePath={basePath} display={{ base: 'none', md: 'flex' }} />
                            <AppRoutes themeName={'PPMPortal'} menuRoutes={menuRoutes} basePath={basePath} />
                        </Flex>
                    </Flex>
                </Router> 
            </AuthenticatedTemplate>

            {/* </PageLayout> */}
        </ChakraProvider>
    );
}

function AppRoutes(props) {

    user.initAuthenticatedUser()
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    const name = accounts[0] && accounts[0].name;

    function RequestAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.accessToken);
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setAccessToken(response.accessToken);
            });
        });
    }

    if (accessToken === null) {
        RequestAccessToken()

    }

    

    let orderedRoutes = [];
  
    //Sections
    for (let i = 0; i < props.menuRoutes.length; i++) {
      for (let k = 0; k < props.menuRoutes[i].length; k++) {
        orderedRoutes.push({
          name: props.menuRoutes[i][k].name,
          url: props.menuRoutes[i][k].url,
          page: props.menuRoutes[i][k].page,
        });
      }
    }
  
    return (
      <Routes>
        <Route path={'/'} element={<Home pageName="DGFisher"  />} />
        {orderedRoutes.map((route, i) => (
          <Route path={'/' + route.url} element={route.page} />
        ))}
      </Routes>
    );
}





/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */



const menuRoutes = [
    //Top Nav
    [
      {
        name: 'home',
        url: '/home',
        icon: 'fas fa-home fa-lg fa-fw',
        page: <Home pageName="DGFisher" />,
      },
      {
        name: 'list',
        url: '/list',
        icon: 'fas fa-server fa-fw fa-lg',
        page: <Components pageName="List" />,
      },
      {
        name: 'calendar',
        url: '/calendar',
        icon: 'fas fa-calendar-alt fa-lg fa-fw',
        page: <MyCalendar pageName="Calendar"  />,
      },
      {
        name: 'payroll',
        url: '/payroll',
        icon: 'fas fa-clock fa-lg fa-fw',
        page: <Home pageName="Payroll" />,
      },
      {
        name: 'contacts',
        url: '/contacts',
        icon: 'fas fa-address-book fa-fw fa-lg',
        page: <Home pageName="Contacts" />,
      },
    ],
    //Sidebar Menu
    [
      {
        name: 'images',
        url: '/images',
        icon: 'fas fa-images fa-lg fa-fw',
        page: <Home pageName="Images" />,
      },
      {
        name: 'favorites',
        url: '/favorites',
        icon: 'far fa-heart fa-fw fa-lg',
        page: <Home pageName="Favorites" />,
      },
      {
        name: 'notes',
        url: '/notes',
        icon: 'far fa-clipboard fa-lg fa-fw',
        page: <Home pageName="Notes" />,
      },
      {
        name: 'statistics',
        url: '/statistics',
        icon: 'fas fa-chart-bar fa-lg fa-fw',
        page: <Home pageName="Statistics" />,
      },
    ],
     //Sidebar Help
    [
      {
        name: 'notifications',
        url: '/notifications',
        icon: 'far fa-bell fa-lg fa-fw',
        page: <Home pageName="Notifications" />,
      },
      {
        name: 'help center',
        url: '/helpcenter',
        icon: 'far fa-question-circle fa-fw fa-lg',
        page: <Home pageName="Help Center" />,
      },
      {
        name: 'settings',
        url: '/settings',
        icon: 'fas fa-cog fa-lg fa-fw',
        page: <Home pageName="Settings" />,
      },
  
    ],
  ];