import { Box,Container,Heading,Stack,HStack,useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { TabLink } from './TabLink'

export const PageHeader = (props) => (
  <Box shadow="md" {...props} bg={useColorModeValue('','whiteAlpha.50')} >
    <Container maxW={(props.maxW ?? "7xl")}>

      <HStack spacing='0' py="3" align='flex-end'>
        <Heading size="2xl" letterSpacing='wider' color={useColorModeValue('gray.600','gray.300')} textTransform='uppercase'>{props.pageName}</Heading>
        {props.pageName === 'DGFisher' &&
          <Heading size="md" letterSpacing='widest' color='gray.500' textTransform='uppercase'>.com</Heading>
        }
        {/* <Heading size="sm" letterSpacing='widest' color={useColorModeValue('gray.500', 'gray.400')} textTransform='uppercase'>TESTING</Heading> */}
      </HStack>
      <Stack textTransform='uppercase' letterSpacing={1} direction="row" spacing="2">
        <TabLink color='gray.400' aria-current="page" href="#">Overview</TabLink>
        <TabLink color='gray.400' href="#">Analytics</TabLink>
        <TabLink color='gray.400' href="#">Automation</TabLink>
      </Stack>
    </Container>
  </Box>
)
