import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spacer,
  HStack,
  IconButton,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { HiOutlineMenu } from 'react-icons/hi'
import { Logo } from './Logo'
import { Sidebar } from './Sidebar'
import { useMobileMenuState } from './useMobileMenuState'
import { ColorModeSwitcher } from './ColorModeSwitcher';


export const MobileTopBar = (props) => {
  const { isOpen,onClose,onOpen } = useMobileMenuState()

  //PPM Portal Theme (Default)
  var bg = mode("gray.50","gray.50")
  var color = mode("gray.600","gray.700")
  var menuRoutes = props.menuRoutes;
  const basePath = props.basePath;

  if (props.themeName === 'PPMPortal') {
    if (isOpen) {
      bg = mode("teal.700","teal.700")
      color = mode("whiteAlpha.800","whiteAlpha.800")
    } else {
      bg = mode("teal.700","teal.600")
      color = mode("whiteAlpha.800","whiteAlpha.800")
    }
  }


  return (
    <Flex
      align="center"
      justify="space-between"
      py="2"
      px="4"
      bg={bg}

      display={{
        base: 'flex',
        md: 'none',
      }}
      borderBottomWidth="1px"
      {...props}
    >
      <Logo />
      <Spacer />
      <HStack spacing='3'>
        <ColorModeSwitcher color={color} justifySelf="flex-end" />
        <IconButton
          onClick={onOpen}
          variant="outline"
          display="flex"
          cursor="pointer"
          aria-label="Menu"
          color={color}
          icon={<HiOutlineMenu fontSize="1.5rem" />}
        />
      </HStack>
      <Drawer
        size="64"
        placement="left"
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent bg={bg} shadow="none" position="relative" maxW="64">
          <Sidebar showLogo={onOpen} isOpen={isOpen} width="full" height="full" bg="inherit" border="0" themeName={props.themeName} menuRoutes={menuRoutes} basePath={basePath} />
          <DrawerCloseButton
            bg={bg}
            _hover={{
              bg: 'teal.600',
            }}
            _active={{
              bg: 'teal.700',
            }}

            position="absolute"
            color="white"
            // right="-8"
            top="2"
          />
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}
