import { Box, Center, IconButton } from '@chakra-ui/react'
import * as React from 'react'
import { FaBell } from 'react-icons/fa'

const NotificationBadge = (props) => (
  <Center
    bg="red.500"
    fontSize="xs"
    fontWeight="bold"
    position="absolute"
    rounded="full"
    zIndex='10'
    textAlign="center"
    top="-1px"
    insetEnd="-1"
    w="18px"
    h="18px"
    {...props}
  />
)

export const Notification = (props) => (
  <Center
    as="button"
    outline="0"
    w="8"
    h="8"
    position="relative"
    rounded="md"
    _hover={{
      bg: 'whiteAlpha.200',
    }}
    _focus={{
      shadow: 'outline',
    }}
    {...props}
  >
    <Box srOnly>Click to see 9 notifications</Box>
    <NotificationBadge>9</NotificationBadge>
    <IconButton
      variant="ghost"
      display="flex"
      cursor="pointer"
      aria-label="Menu"
      color={'whiteAlpha.800'} 
      icon={<FaBell fontSize="1.5rem" />}
    />
  </Center>
  
)
