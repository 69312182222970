import React from 'react';
import { Flex,Box,chakra,Container,Heading,Stack,HStack,Tabs,TabList,Tab,TabPanels,TabPanel } from '@chakra-ui/react'
import { motion,Reorder } from 'framer-motion'

import { PageHeader } from './PageHeader'
import { PageContent } from './PageContent'

import { DGFisherAPI } from './apis/dgfisherAPI'


const List = chakra(Reorder.Group)
const ListItem = chakra(Reorder.Item)

export class Home extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      tabVal: 0,
      DGFisherAPI: new DGFisherAPI(),
      data: null,
      
    }

    this.handleTabChange = this.handleTabChange.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.init = this.init.bind(this);

  }

  componentDidMount() {
    this.init();
  }

  handleTabChange(tabVal) {
    this.setState({ tabVal: tabVal });
  }

  closeForm() {
    this.setState({ tabVal: 0 });
  }

  async init() {
    let data = this.state.data
    let DGFisherAPI = this.state.DGFisherAPI
    if (data === null) {
      let dates = await DGFisherAPI.GetDates();
      if (dates?.status === 200) {
        data = dates.value;
        this.setState({ data: data });
        console.log('DATA')
        console.log(data)
      }

    }

  }

  render() {

    return (
      // <MainContent onTabChange={this.handleTabChange} tabVal={this.state.tabVal} {...this.props}>


      <Flex direction='column' flex={1} w='full' bgGradient="linear(to-b, blackAlpha.500 90%, blackAlpha.400 )"  >
        <Flex direction='column' w='full' bg='white' >
          {/* <PageHeader pt='4' px='4' pageName={this.props.pageName} /> */}
          <Box shadow="md"  >
            <Container maxW="8xl">

              <HStack spacing={1} py="3" align='flex-end' color='gray.500'>
                <Heading size="2xl" letterSpacing='widest' textTransform='uppercase' >{this.props.pageName}</Heading>
                {this.props.pageName === 'DGFisher' &&
                  <Heading size="md" letterSpacing='widest' textTransform='uppercase' color='blackAlpha.400'>.com</Heading>
                }
                {/* <Heading size="sm" letterSpacing='widest' color={useColorModeValue('gray.500', 'gray.400')} textTransform='uppercase'>TESTING</Heading> */}
              </HStack>
              <Tabs colorScheme='teal' color='gray.400' >
                <TabList>
                  <Tab><Heading size='md' textTransform='uppercase' fontWeight='semibold'>One</Heading></Tab>
                  <Tab><Heading size='md' textTransform='uppercase' fontWeight='semibold'>Two</Heading></Tab>
                  <Tab><Heading size='md' textTransform='uppercase' fontWeight='semibold'>Three</Heading></Tab>
                </TabList>
              </Tabs>


            </Container>
          </Box>
        </Flex>


        <PageContent px={{ base: '0',lg: '2' }} data={this.state.data} />

      </Flex>

      //  <Flex
      //   display={{base: 'none', lg: 'block',}}
      //   width="xs"
      //   direction="column"
      //   overflowY="auto"
      //   borderRightWidth="1px"
      //   p="6"
      //   shadow='lg'
      //   bg='teal.700' 

      // >
      //   <Box borderWidth="2px" borderColor='whiteAlpha.300' rounded="base" borderStyle="dashed" h="full" bg='whiteAlpha.200' />
      // </Flex> 


      // </Flex>


      // </MainContent> 


    );
  }
}


export class Components extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      tabVal: 0,
    }

    this.handleTabChange = this.handleTabChange.bind(this);
    this.closeForm = this.closeForm.bind(this);

  }

  handleTabChange(tabVal) {
    this.setState({ tabVal: tabVal });
  }

  closeForm() {
    this.setState({ tabVal: 0 });
  }

  render() {

    return (



      <Flex flex="1" w='full' bgGradient="linear(to-b, blackAlpha.100 75%, blackAlpha.200 )"  >
        <Flex direction='column' w='full' >
          <PageHeader pt='4' px='4' pageName={this.props.pageName} />
          <DraggableList />
        </Flex>
      </Flex>




    );
  }
}







export const DraggableList = () => {
  const [items,setItems] = React.useState([
    'blue.200',
    'blue.300',
    'blue.400',
    'blue.500',
    'blue.600',
  ])
  return (
    <Box
      maxW={{
        sm: 'sm',
      }}
      mx="auto"
      p={{
        base: '4',
        md: '8',
        lg: '12',
      }}
    >
      <List values={items} onReorder={setItems} listStyleType="none">

        {items.map((item) => (
          // <motion.div
          //   // animate={{ x: 100 }}
          //   transition={{ ease: "easeOut", duration: .9 }}
          //   whileHover={{ scale: 1.05 }}
          //   // whileTap={{ scale: 0.95 }}
          // >
          <ListItem
            key={item}
            value={item}
            height="16"
            mb="4"
            bg={item}
            shadow='md'
            rounded='5'
            w='sm'

            position="relative"
            borderRadius="lg"
            cursor="grab"
            whileHover={{
              scale: 1.02,
            }}
            whileTap={{
              cursor: 'grabbing',
              scale: 1.1,
            }}

          >


          </ListItem>

          // </motion.div>
        ))}
      </List>


    </Box>
  )
}