import { Box,Flex,HStack,Stack } from '@chakra-ui/react'
import * as React from 'react'
import { HiDuplicate,HiMail,HiRefresh,HiTemplate,HiViewGrid } from 'react-icons/hi'
import { NavItem } from './NavItem'
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all';

import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

function MobileNavMenu(props) {
  const { isOpen } = props
  let menuRoutes = props.menuRoutes[0];
  let location = useLocation();
  return (
    <Flex
      hidden={!isOpen}
      as="nav"
      direction="column"
      bg="teal.600"
      position="fixed"
      height="calc(100vh - 4rem)"
      top="16"
      insetX="0"
      zIndex={10}
      w="full"
    >
      <Box px="4">
        {menuRoutes.map((route,i) =>
          <NavItem.Mobile active={('/' + route.name.replace(/\s/g,'') === location.pathname ? true : false)} icon={<i className={route.icon} />} label={route.name} url={route.url} />
        )}
      </Box>
    </Flex>
  )
}

function DesktopNavMenu(props) {
  let menuRoutes = props.menuRoutes[0];
  let location = useLocation();
  return (
    <HStack
      spacing="3"
      flex="1"
      display={{
        base: 'none',
        lg: 'flex',
      }}
      textTransorm='uppercase'
    >
      {menuRoutes.map((route,i) =>
        <NavItem.Desktop active={('/' + route.name.replace(/\s/g,'') === location.pathname ? true : false)} icon={<i className={route.icon} />} label={route.name} url={route.url} />
      )}
    </HStack>
  )
}

function SidebarNavMenu(props) {
  const { isOpen } = props
  let menuRoutes = props.menuRoutes;
  let location = useLocation();

  return (
    <Stack
      spacing="3"
      flex="1"
      textTransorm='uppercase'
    >
      {menuRoutes.map((route,i) =>
        <NavItem.Sidebar active={('/' + route.name.replace(/\s/g,'') === location.pathname ? true : false)} icon={<i className={route.icon} />} label={route.name} url={route.url} />
      )}
    </Stack>
  )
}

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
  Sidebar: SidebarNavMenu,
}
