import { Flex,HStack,Spacer,Heading,useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import { Logo } from './Logo'
import { NavMenu } from './NavMenu'
import { Notification } from './Notification'
import { ProfileDropdown } from './ProfileDropdown'
import { useMobileMenuState } from './useMobileMenuState'
import { ColorModeSwitcher } from './ColorModeSwitcher';



export const TopNav = (props) => {
  const { isOpen, onClose, onOpen } = useMobileMenuState()


  //PPM Portal Theme (Default)
  var bg = mode("gray.50","gray.50")
  var color = mode("gray.600","gray.700")
  var menuRoutes = props.menuRoutes;
  const basePath = props.basePath;

  if (props.themeName === 'PPMPortal') {

    bg = mode("teal.600","teal.700")
    color = mode("whiteAlpha.800","whiteAlpha.800")

  }

  return (

    <Flex

      bgGradient="linear(to-r, teal.700 40%, teal.600 )"
      color={color}
      direction='row'
      minH="16"
      justify="space-between"
      w="full"
      borderBottom='1px'
      shadow='lg'

      align='center'
      display={{
        base: 'none',
        md: 'flex',
      }}>




      {/* Desktop Logo placement */}
      <Flex
        w={64} h='100%'
        align='inherit'
        bgGradient="linear(to-r, teal.900, teal.700)"

      >
        <Logo pl="4" h="10" />
      </Flex>
      {/* <Flex>
            <Heading size='md' textTransform='uppercase' letterSpacing={2} pr='4'>
              Employee Portal
            </Heading>
          </Flex> */}

      {/* Desktop Navigation Menu */}
      <NavMenu.Desktop menuRoutes={menuRoutes} basePath={basePath} />

      <Spacer />

      <HStack spacing="3" px="4" >
        <ColorModeSwitcher color={color} justify='center' />
        {/* justifySelf="flex-end" */}
        <Notification color={color} justify='center' />
        <ProfileDropdown handleLogout={props.handleLogout} />
      </HStack>

    </Flex>

  )
}
