export function getDeviceType() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return('mobile');
    } else {
      return ('pc');
    }
}

export function log(primary, secondary=false, prod = false) {
    if (process.env.NODE_ENV === "development" || prod) {
      if (secondary) {
        console.log(primary);
        console.log(secondary);
      } else {
        console.log(primary);
      }
    }
}

export function b64toBlob(b64Data, contentType='', sliceSize=512) {
    log('b64', b64Data);
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  
  export function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], {type: mimeString});
    return blob;
  }
  
  export function formatNumber(value, numDigits, style) {
    // if (!Boolean(value) || isNaN(value)) {
    //   return 0;
    // }
    let formattedNumber = (new Intl.NumberFormat('en-US', {
      style: style ?? undefined,
      minimumFractionDigits: numDigits ?? 0,
      maximumFractionDigits: numDigits ?? 0
    })).format(value);
    return formattedNumber;
  }
  
  export const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });


  export function formatDate(dateStr, keepTime) {
    if (!Boolean(dateStr)) { return dateStr; }
    if (dateStr instanceof Date) { dateStr = dateStr.toISOString(); }
    // Format works with IOS
    let timeStr = ' 00:00:00';
    if (keepTime) {
      timeStr = dateStr?.replace(/.*(\d{2}:\d{2}:\d{2}).*/gm, ' $1Z');
    }
    dateStr = dateStr?.replace(/T.*/gm, timeStr)?.replace(/-/gm, '/');

    return dateStr;
}
  

export function convertArrayToMap(arr, keyAttr, addDuplicates) {
    let map = {};
    
    if (addDuplicates) {
      for (let i = 0; i < arr?.length; i++) {
        if (!Boolean(map[arr[i][keyAttr]])) {
          map[arr[i][keyAttr]] = [];
        }
        map[arr[i][keyAttr]].push(arr[i])
      }
    } else {
      for (let i = 0; i < arr?.length; i++) {
        map[arr[i][keyAttr]] = arr[i];
      }
    }
    return map;
}